import React, { useCallback, useState } from "react";

export const createUserContextValue = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [loginInfo, setLoginInfo] = useState(
    JSON.parse(window.localStorage.getItem("loginInfo") || "{}")
  );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const login = useCallback((loginInfo) => {
    window.localStorage.setItem("loginInfo", JSON.stringify(loginInfo));
    setLoginInfo(loginInfo);
  }, []);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const logout = useCallback(() => {
    window.localStorage.removeItem("loginInfo");
    setLoginInfo({});
    window.location.assign("https://clickef.com/t-phone/Login/logOut");
  }, []);

  return {
    email: loginInfo?.email,
    name: loginInfo?.name,
    login,
    logout,
  };
};

export const UserContext = React.createContext(null);

export const UserContextProvider = (props) => {
  const userContext = createUserContextValue();

  return (
    <UserContext.Provider value={userContext}>
      {props.children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => React.useContext(UserContext);
