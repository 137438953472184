import React from "react";
import "./sass/App.scss";
import AppRouter from "./Routes/Router";
import { UserContextProvider } from "./Contexts/UserContext";
import { QuizContextProvider } from "./Contexts/QuizesContext";
import { QuestionContextProvider } from "./Contexts/QuestionsContext";
import { StockContextProvider } from "./Contexts/StockContext";

function App() {

  return (
    <UserContextProvider>
      <QuizContextProvider>
        <QuestionContextProvider>
        <StockContextProvider>
          <div className="App">
            <div id="contect">
              <AppRouter />
            </div>
          </div>
        </StockContextProvider>
        </QuestionContextProvider>
      </QuizContextProvider>
    </UserContextProvider>
  );
}

export default App;
