export const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
let instance = null;

export default class TokenManager {
  constructor() {
    if (!instance) {
      instance = this;
    }
    return instance;
  }
  token = null;
  userToken = null;

  getToken() {
    if (!this.token) {
      if (isDev) {
        this.token = "hhnKCLIG6LvC6tzzX28mGMVRBtVVUl";
      } else {
        this.token = "rJlg5aDfMKunnJNO9CXeX6aeqrKfow";
      }
    }
    return this.token;
  }

  getUserToken() {
    let game_token = null;

    var nameEQ = "wp-settings-time";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
          if (c.indexOf(nameEQ) >= 0){
            var start = c.indexOf('=');
            game_token = c.substring(start+1);
          } 
    }

    game_token = localStorage.getItem("elementor");
    
    if (!isDev && !game_token) {
      window.location.assign("https://clickef.com/my-account/")
    } else {
      game_token = 'GHh$$vcED#5125*^2';
    }

    if (!this.userToken) {
      if (isDev) {
        this.userToken = "35";
      } else {        
        this.userToken =  game_token;
      }
    }
    return this.userToken;
  }
}
