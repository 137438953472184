import Axios from "axios";
import TokenManager from "./TokenMgr";
//const SERVER_ADDRESS = "https://clickef.com/t-phone/public/react/public";
const SERVER_ADDRESS = "https://clickef.com/t-phone/public/react/new_p";
//const SERVER_ADDRESS = "http://tphone.local";
const QUIZ_ADDRESS = `${SERVER_ADDRESS}/quiz`;
const GAMERS_ADDRESS = `${SERVER_ADDRESS}/gamers`;
const QUESTION_ADDRESS = `${SERVER_ADDRESS}/question`;
const STOCK_ADDRESS = `${SERVER_ADDRESS}/stock`;

const getTokens = () => {
  const tokenMgr = new TokenManager();
  return {
    token: tokenMgr.getToken(),
    userToken: tokenMgr.getUserToken(),
  };
};

export const getUserDetails = () => {
  return Axios.post(`${SERVER_ADDRESS}/api/loadUser`, getTokens());
};

export const getQuizes = () => {
  return Axios.post(`${QUIZ_ADDRESS}/getList`, getTokens());
};

export const getSettings = (quizId) => {
  return Axios.post(`${QUIZ_ADDRESS}/getSettings/${quizId}`, getTokens());
};
export const editQuiz = (quizId, payload) => {
  return Axios.post(`${QUIZ_ADDRESS}/saveSetting/${quizId}`, {
    ...getTokens(),
    params: payload,
  });
};

export const getQuizGamers = (quizId) => {
  return Axios.post(`${GAMERS_ADDRESS}/getList/${quizId}`, getTokens());
};
export const getQuizGroups = (quizId) => {
  return Axios.post(`${GAMERS_ADDRESS}/getGroups/${quizId}`, getTokens());
};
export const saveQuizGamers = (quizId, payload) => {
  return Axios.post(`${GAMERS_ADDRESS}/saveList/${quizId}`, {
    ...getTokens(),
    params: payload,
  });
};

export const importQuizfile = (quizId, file) => {
  const data = new FormData()
  const tokenMgr = new TokenManager();
  data.set('token', tokenMgr.getToken());
  data.set('userToken', tokenMgr.getUserToken());
  data.append('file', file);
  return Axios.post(`${QUIZ_ADDRESS}/import/${quizId}`, data);
};

export const exportUsersQuiz = (quizId) => {
  //Axios.post(`${STOCK_ADDRESS}/add/${quizId}`, getTokens());
  return Axios.post(`${QUIZ_ADDRESS}/export/${quizId}/Users`, getTokens());
};

export const exportQuestionsQuiz = (quizId) => {
  //Axios.post(`${STOCK_ADDRESS}/add/${quizId}`, getTokens());
  return Axios.post(`${QUIZ_ADDRESS}/export/${quizId}/Questions`, getTokens());
};

export const getQuestions = (quizId) => {
  return Axios.post(`${QUESTION_ADDRESS}/getList/${quizId}`, getTokens());
};

export const getQuestionSettings = (questionId) => {
  return Axios.post(`${QUESTION_ADDRESS}/getSettings/${questionId}`, getTokens());
};

export const addQuestions = (quizId, payload) => {
  console.log('payload on add', payload);

  return Axios.post(`${QUESTION_ADDRESS}/add/${quizId}`, {
    ...getTokens(),
    params: payload,
  });
};

export const editQuestions = (quizId, payload) => {
  console.log('payload on edit', payload);
  return Axios.post(`${QUESTION_ADDRESS}/saveSettings/${quizId}`, {
    ...getTokens(),
    params: payload,
  });
};

export const reorder = (quizId, payload) => {
  return Axios.post(`${QUESTION_ADDRESS}/saveListWeight/${quizId}`, {
    ...getTokens(),
    params: payload,
  });
};


export const getTags = () => {
  return Axios.post(`${QUESTION_ADDRESS}/getTags`, {
    ...getTokens()
  });
};


export const deleteQuestion = (id) => {
  return Axios.post(`${QUESTION_ADDRESS}/remove/${id}`, {
    ...getTokens()
  });
};

export const getStockList = (index, payload) => {
  return Axios.post(`${STOCK_ADDRESS}/getList/${index}`, {
    ...getTokens(),
    params: payload,
  })
}

export const addQuestionsFromStock = (quizId, payload) => {
  return Axios.post(`${STOCK_ADDRESS}/addToQuiz/${quizId}`, {
    ...getTokens(),
    params: payload,
  })
}

export const getQuizDetails = (quizId) => {
  return Axios.post(`${SERVER_ADDRESS}/api/userQuiz/${quizId}`, getTokens());
};