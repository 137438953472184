import React from "react";
import { useQuizContext } from "../Contexts/QuizesContext";
import { quizType } from "../utils/types";

export default function Groups() {
  const { currentQuiz, setCurrentQuiz } = useQuizContext();

  const addCategory = () => {
    const { value } = document.getElementById('add-category');
    if (!value) return;
    const g = ([...currentQuiz?.groups, { category: value, list: [] }]);
    document.getElementById('add-category').value = '';
    setCurrentQuiz({ ...currentQuiz, groups_changed: true, hasChanged: true });
    setCurrentQuiz({ ...currentQuiz, groups: g });
  };

  const removeCategory = (index) => {
    const g = [];
    delete currentQuiz.groups[index];
    currentQuiz.groups.forEach(element => {
      if (element !== "") {
        g.push(element);
      }
    });
    setCurrentQuiz({ ...currentQuiz, groups: g });
    setCurrentQuiz({ ...currentQuiz, groups_changed: true , hasChanged: true});

  };

  const addGroup = (i) => {
    const { value } = document.getElementById('add-group-' + i);
    if (!value) return;
    currentQuiz.groups[i].list.push({ name: value });
    const g = [];
    currentQuiz.groups.forEach(element => {
      g.push(element);
    });
    document.getElementById('add-group-' + i).value = '';
    setCurrentQuiz({ ...currentQuiz, groups: g });
    setCurrentQuiz({ ...currentQuiz, groups_changed: true , hasChanged: true});
  };

  const removeGroup = (i, j) => {
    const g = [];
    let list;
    delete currentQuiz.groups[i].list[j];
    currentQuiz.groups.forEach(element => {
      list = [];
      element.list.forEach(l => {
        if (l !== "") {
          list.push(l);
        }
      })
      element.list = list;
      g.push(element);
    });
    setCurrentQuiz({ ...currentQuiz, groups: g });
    setCurrentQuiz({ ...currentQuiz, groups_changed: true , hasChanged: true});
  }

  const checkAdd = (e) => {
    if (e.key === "Enter") {
      addCategory();
    }
  }

  const checkAddGroup = (e, i) => {
    if (e.key === "Enter") {
      addGroup(i);
    }
  }

  return 1 || currentQuiz?.type === quizType.CLICKEF_EVENTS ||
    currentQuiz?.type === quizType.CLICK_4U ? (
      <div className="groups">
        <div className="groups-title">קבוצות</div>
        <div className="items-categories">
          {currentQuiz?.groups?.map((group_category, i) => (
            <div key={i} className={"category category-" + i}>
              <div className="category-name">
                <div>{group_category?.category}</div>
                <button onClick={() => removeCategory(i)}>מחק קטגוריה</button>
              </div>
              <div className="items">
                {group_category?.list?.map((group, j) => (
                  <div key={i + '-' + j} className={"item item-" + j}>
                    <input disabled type="text" value={group.name} />
                    <button onClick={() => removeGroup(i, j)}>X</button>
                  </div>
                ))}
                <div className={"item add"}>
                  <input type="text" id={"add-group-" + i} onKeyPress={(e) => { checkAddGroup(e, i) }} placeholder="הוסף קבוצה" />
                  <button onClick={() => { addGroup(i) }}>+</button>
                </div>
              </div>
            </div>
          ))}
          <div className="category add">
            <input type="text" id="add-category" onKeyPress={checkAdd} placeholder="הקלד שם קטגוריה..." />
            <button style={{ width: '100%' }} onClick={addCategory}>הוסף קטגוריה</button>
          </div>

        </div>
      </div>
    ) : null;
}
