import React from "react";
import { useQuizContext } from "../Contexts/QuizesContext";
import { Switch, Radio } from "pretty-checkbox-react";
import { quizType } from "../utils/types";
import 'pretty-checkbox';
import { useHistory, useRouteMatch } from "react-router-dom";
import { QUIZ_GAMERS_PATH } from "../Routes/RoutesPath";

export default function PhoneBooleans() {
  const history = useHistory();
  const { currentQuiz, setCurrentQuiz } = useQuizContext();

  const handleLockChanged = (e) => {
    const { checked } = e.nativeEvent.target;
    setCurrentQuiz({ ...currentQuiz, lock_users: checked? "1": "0" , hasChanged: true});
  };
  const handlePinChanged = (e) => {
    const { checked } = e.nativeEvent.target;
    setCurrentQuiz({ ...currentQuiz, with_code: checked? "1": "0", hasChanged: true });
  };
  const handleRemoteChanged = (val) => {
    setCurrentQuiz({ ...currentQuiz, remote: val , hasChanged: true});
  };

  console.log('currentQuiz?.remote', currentQuiz?.remote);
  return (true || currentQuiz?.type === quizType.PHONE_CLICK) ? (
    <>
      <div className="form-item">
        <b>אפשרויות השתתפות בחידון:</b><br></br>
        <Switch id="lock-users" state={currentQuiz?.lock_users !== '0'} onChange={handleLockChanged} >
          <div for="lock-users">רשומים בלבד</div>
        </Switch>
      </div>
      <div className="form-item">
        <b>זיהוי שמות המשתמשים באמצעות:</b>
        <br></br>
        מספר טלפון&nbsp;
        <Switch id="with-code" state={currentQuiz?.with_code !== '0' } onChange={handlePinChanged}>
          <div for="with-code">קוד אישי</div>
        </Switch>
      <div class="tip"><small>*(רישום מספרי טלפון או קודים אישיים לזיהוי במסך הבא)</small>
      </div></div>
      <div className="form-item">
        <div>סוג ארוע</div>
        <div>
          <div className="item-0">
            <Radio name="remote" disabled id="remote-0" checked={!currentQuiz?.remote || currentQuiz?.remote === "0"} onChange={() => handleRemoteChanged("0")}>
              <div>מסך ניהול בלבד</div>
            </Radio>
          </div>
          <div className="item-1">
            <Radio name="remote" disabled id="remote-1" checked={currentQuiz?.remote === "1"} onChange={() => handleRemoteChanged("1")}>
              <div>כולל מסך תצוגה</div>
            </Radio>
          </div>
        </div>
      </div>
    </>
  ) : null;
}
