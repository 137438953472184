import React, { useState, useCallback, useRef } from "react";
import ReactAudioPlayer from 'react-audio-player';
import '../sass/_global.scss'
import AlertDialog from "./Dialog";
import { getType } from "../utils/FileCreator";
import { ToastContainer, toast } from 'react-toastify';
import Cropper from 'react-easy-crop'


function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);
    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
}

const ImgUploader = props => {
    const { hasImage, removeImage, background_image, uploadFromUrl, type, mediaObject } = props;
    const [dialogOpen, setDialogOpen] = useState(false);

    const [editImage, setEditImage] = useState(false);

    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [rotation, setRotation] = useState(0);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState({});
    const canvas = useRef(null);
    const img = useRef(null);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, [])

    const saveFile = () => {
        let ctx = canvas.current.getContext('2d');
        ctx.clearRect(0, 0, canvas.current.width, canvas.current.width);
        ctx.save();
        ctx.translate((canvas.current.width / 2), (canvas.current.height / 2));
        ctx.rotate(rotation * Math.PI / 180);
        ctx.drawImage(img.current, -croppedAreaPixels.x, -croppedAreaPixels.y, croppedAreaPixels.width, croppedAreaPixels.height);
        ctx.restore();
        //let imgUrl = canvas.current.toDataURL();
        //let file = dataURItoBlob(imgUrl);
        //props.setPicture(file);
        //setEditImage(false);
    }

    const renderPreview = () => {
        const mediaType = mediaObject?.type || (background_image && getType(background_image));
        if (!mediaType) {
            console.log("no preview");
            return;
        }
        const src = (mediaObject && mediaObject != {}) ? window.URL.createObjectURL(mediaObject) : background_image;
        if (mediaType.startsWith("video")) {
            return <video width="200" controls>
                <source src={src} type={mediaType} />
            </video>
        }
        else if (mediaType.startsWith("audio")) {
            return <ReactAudioPlayer
                src={src}
                controls
            />
        }
        else if (mediaType.startsWith("image")) {
            //return <img alt="תמונה מצורפת" className="preview-question" src={src} />
            return <>
                {editImage ?
                    (<>
                        <div onClick={() => { setRotation(rotation + 90) }}>{'->'}</div>
                        <div
                            style={{
                                position: 'relative',
                                width: '250px',
                                height: '250px'
                            }}
                        >
                            <Cropper
                                image={src}
                                crop={crop}
                                zoom={zoom}
                                rotation={rotation}
                                showGrid={false}
                                onCropChange={setCrop}
                                onCropComplete={onCropComplete}
                                onZoomChange={setZoom}
                            />
                        </div>
                        <img src={src} ref={img} hidden={true} />
                        <canvas
                            ref={canvas}
                        />
                        <div onClick={() => { setRotation(rotation - 90) }}>{'<-'}</div>
                        <button onClick={saveFile}>שמור שינויים</button>
                        <button onClick={() => { setEditImage(false) }}>ביטול</button>
                    </>) :
                    (<>
                        <img alt="תמונה מצורפת" className="preview-question" src={src} />
                        <button onClick={() => { setEditImage(true) }}>ערוך</button>
                    </>)}
            </>
        }


    }

    return (
        <>
            {renderPreview()}
            {<button className="chooseFileButton" onClick={() => setDialogOpen(true)}> העלה מדיה מלינק </button>}
            {hasImage && <button className="chooseFileButton" onClick={removeImage}>הסר מדיה </button>}
            <AlertDialog title="URLהעלה מדיה מ" dialogOpen={dialogOpen} onOk={() => { setDialogOpen(false); uploadFromUrl(); }} onCancel={() => { setDialogOpen(false); }} okText="העלה" showInput />
            <input type="file"
                title="בחר מדיה"
                className="chooseFileButton"
                accept={type != 1 ? "video/*, audio/*, image/*" : "image/*"} onChange={e => {
                    var sizeInMB = (e.nativeEvent.target.files[0].size / (1024 * 1024)).toFixed(2);
                    if (sizeInMB > 25) {
                        toast.error("25MB אנא הכנס קובץ עד");
                        return;
                    }
                    props.setPicture(e.nativeEvent.target.files[0])
                }} />
            <ToastContainer
                position="bottom-center"
                autoClose={3000}
                type="error"
            />

        </>
    );
};

export default ImgUploader;