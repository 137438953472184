import React, { useState, useEffect } from "react";
import { useQuizContext } from "../Contexts/QuizesContext";
import { Switch } from "pretty-checkbox-react";
import 'pretty-checkbox';

export default function QuizTimer() {
  const { currentQuiz, setCurrentQuiz } = useQuizContext();
  const [showTimer, setShowTimer] = useState(currentQuiz?.timer_default > 0);

  const handleChange = (e) => {
    const { checked } = e.nativeEvent.target;
    setShowTimer(checked);
    if (!checked) {
      setCurrentQuiz({ ...currentQuiz, timer_default: 0 , hasChanged: true});
    }

  };

  const handleTimerChanged = (e) => {
    const { value } = e.nativeEvent.target;
    setCurrentQuiz({ ...currentQuiz, timer_default: value , hasChanged: true});
  };

  useEffect(() => {
    if (currentQuiz?.timer_default > 0) {
      setShowTimer(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuiz?.timer_default])

  return (
    <div className="form-item">
      <Switch state={showTimer} onClick={handleChange} id={"checkBox"}>
        <div>טיימר ברירת מחדל לשאלות</div>
      </Switch>
      {showTimer && (
        <div className="form-item-text">
          <label for="picker">מספר שניות:</label>
          <input type="number" id="picker" value={!currentQuiz?.timer_default || currentQuiz?.timer_default === "0" ? 30 : currentQuiz?.timer_default} onChange={handleTimerChanged} />
        </div>
      )}
    </div>
  );
}
