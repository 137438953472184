import React, { useState, useEffect } from "react";
import { Collapse, Radio } from "@material-ui/core";
import { useQuestionContext } from "../Contexts/QuestionsContext";

export default function QuestionTimer() {
    const [timerOpen, setTimerOpen] = useState(true);
    const { setCurrentQuestion, currentQuestion } = useQuestionContext();
    const [timerType, setTimerType] = useState();

    const saveTimer = (e) => {
        const { value } = e.nativeEvent.target;
        setCurrentQuestion({ ...currentQuestion, timer: value , hasChanged : true})
    }
    const cancelTimer = () => {
        setCurrentQuestion({ ...currentQuestion, timer: null , hasChanged : true})
    }

    useEffect(() => {
        if (!currentQuestion.timer || currentQuestion.timer === 'default') {
            setTimerType("default")
        }
        else {
            setTimerType("timer")
        }
    })
    return (
        <div className="question-timer fieldset-wrap">
            <div className={"timer-title fieldset-title" + (timerOpen ? " open" : "")} onClick={() => setTimerOpen(!timerOpen)} >הגדרות טיימר</div>
            <Collapse in={timerOpen} className="timer-data fieldset-content" >
                <div>
                    <Radio name="timer" checked={timerType === "default"} onChange={() => saveTimer({ nativeEvent: { target: { value: "default" } } })} />
                    <label>ברירת מחדל</label>
                </div>
                <div>
                    <Radio name="timer" onChange={() => saveTimer({ nativeEvent: { target: { value: "40" } } })} checked={timerType === "timer"} />
                    <label>
                        <input type="number" checked={timerType == "timer"} value={!currentQuestion.timer || currentQuestion.timer === "default" ? 40 : currentQuestion.timer} placeholder="הכנס מספר שניות" onChange={saveTimer} />
                    </label>
                </div>
            </Collapse>
        </div>
    );
}