import React, { useEffect } from "react";
import { Prompt } from 'react-router';
import QuizMenu from "../Components/QuizMenu";
import ChooseQuizCategory from "../Components/ChooseQuizCategory";
import "../sass/Quiz.scss";
import "../sass/QuizSetting.scss";
import { useQuizContext } from "../Contexts/QuizesContext";
import QuizTimer from "../Components/QuizTimer";
import SaveQuiz from "../Components/SaveQuiz";
import PhoneBooleans from "../Components/PhoneBooleans";
import Groups from "../Components/Groups";
import Loading from "../Components/Loading";
import Stepper from "../Components/Stepper";

export default function QuizSettings() {
  const {
    categoriesList,
    setCurrentQuiz,
    currentQuiz,
    loading
  } = useQuizContext();

  
  const handleChange = (key) => {
    const { value } = document.getElementById(key);
    setCurrentQuiz({ ...currentQuiz, [key]: value , hasChanged: true});
  };

  return (
    <div>
      <Prompt
        when={currentQuiz?.hasChanged === true}
        message="ישנם שינויים שלא נשמרו, האם אתה בטוח שברצונך לעזוב את הדף?"
      />
      <Stepper />
      <div className="quiz-content">
        {loading && <Loading />}
        <div id="quiz-setting-page">
          <div className="quiz-setting-wrap">
            <div className="form-item form-item-text">
              <label for="name">שם חידון:</label>
              <input id="name" value={currentQuiz?.name} onChange={() => handleChange("name")} />
            </div>
            <div className="form-item form-item-text">
              <label for="subject">נושא חידון:</label>
              <input id="subject" value={currentQuiz?.subject} onChange={() => handleChange("subject")} />
            </div>
            {/* <ChooseQuizType /> */}
            {/*<ChooseQuizCategory categories={categoriesList} />*/}
            <QuizTimer />
            <PhoneBooleans />
          </div>
          <div className="quiz-settings-groups">
            <Groups />
          </div>
        </div>
        {currentQuiz?.hasChanged === true ?
          <SaveQuiz /> : ""}
      </div>
    </div>
  );
}
