import React, { useEffect } from "react";

import "../sass/MyQuizes.scss";
import QuizItem from "../Components/QuizItem";
import { useQuizContext } from "../Contexts/QuizesContext";
import { getQuizes } from "../utils/APIs";
import Loading from "../Components/Loading";

export default function MyQuizes() {
  const { myQuizes, setMyQuizes, setLoading, loading } = useQuizContext();
  
  useEffect(() => {
    setLoading(true);
    getQuizes()
      .then((res) => {
        const { quiz_list } = res.data.data;
        const tmp = {};
        quiz_list.forEach((element) => {
          tmp[element.id] = element;
        });
        setMyQuizes(tmp);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("Error!", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  {console.log(myQuizes)}
  function addNew(){
    window.location.href = "/shop/";
  };
  return (
    
    <>
      {loading && <Loading />}
      <div id="main-page">
        <div className="quiz-list">
          <div className="quiz-list-title"><p>לחצו על החידון שהנכם רוצים לערוך</p><p>(ממויין לפי מספר משחק)</p></div>
          <ul>
            {(Object.keys(myQuizes).length > 0) ? (
              Object.keys(myQuizes).map((id, index) => <QuizItem id={id} />)):(<div><br></br>
                עדיין לא רכשתם חידון? 
                <a href="/shop">מצפים לראות אתכם איתנו!</a>
              </div>)}
          </ul>
          <div class="add" onClick={addNew}>+</div>
        </div>
        
      </div>
    </>
  );
}
