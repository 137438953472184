/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useRef } from 'react';
import ReactTags from 'react-tag-autocomplete'
import { getTags } from '../utils/APIs';
import { useQuestionContext } from '../Contexts/QuestionsContext';
import '../sass/Autocomplete.scss'

export default function Autocomplete() {

    const { currentQuestion, setCurrentQuestion, removedTags, setRemovedTags, addedTags, setAddedTags } = useQuestionContext();
    const tagRef = useRef();
    const [tags, setTags] = useState([]);

    useEffect(() => {
        getTags().then(res => {
            setTags(res.data.tags.map(item => ({ tag_id: item.id, name: item.name })));
        });
    }, [])

    const onDelete = (i) => {
        if (i < 0) return;
        const addedIndex = addedTags.indexOf(currentQuestion.tags[i].name);
        if (addedIndex === -1) {
            removedTags.push(currentQuestion.tags[i].tag_id);
            setRemovedTags(removedTags);
        }
        else {
            addedTags.splice(addedIndex, 1);
            setAddedTags(addedTags);
        }
        const tags = currentQuestion.tags.slice(0);
        tags.splice(i, 1)
        setCurrentQuestion({ ...currentQuestion, tags , hasChanged : true});
    }

    const onAddition = (tag) => {
        if ((currentQuestion.tags && currentQuestion.tags.find(item => item.name === tag.name))) return;
        const removedIndex = tag.tag_id && removedTags.indexOf(tag.tag_id)
        if (!tag.tag_id || removedIndex === -1) {
            addedTags.push(tag.name);
            setAddedTags(addedTags);
        }
        else {
            removedTags.splice(removedIndex, 1);
            setRemovedTags(removedTags);

        }
        const tags = currentQuestion.tags ? [].concat(currentQuestion.tags, tag) : [{ ...tag }]
        setCurrentQuestion({ ...currentQuestion, tags , hasChanged : true})
    }

    return (
        <ReactTags
            ref={tagRef}
            tags={currentQuestion.tags || []}
            suggestions={tags}
            onDelete={onDelete}
            onAddition={onAddition}
            placeholderText="הוסף תגית  "
            addOnBlur
            allowNew
        />
    );
}
