import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import "../sass/Quiz.scss";
import { useQuizContext } from "../Contexts/QuizesContext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from "../Components/Loading";
import { QUIZ_PATH, QUIZ_SETTINGS_PATH, QUIZ_QUESTIONS_PATH, QUIZ_IMPORT_PATH, QUIZ_GAMERS_PATH } from "../Routes/RoutesPath";
import { getSettings, exportQuestionsQuiz } from "../utils/APIs";
import { ExportDialog } from "../Components/Dialog";
import { unstable_renderSubtreeIntoContainer } from "react-dom";


export default function Quiz() {

  const history = useHistory();
  const [open, setOpen] = useState(false);

  const { currentQuiz, setCategoriesList, loading, setLoading, setCurrentQuiz, setMyQuizes, myQuizes } = useQuizContext();
  let { id } = useRouteMatch(QUIZ_PATH).params;
  //console.log("the id:"+id);
  //let gameId = '';
  //if(id.indexOf("&") >= 0) {
  //  let parm = id;
  //  gameId = parm.substr(parm.indexOf("&")+1);
  //  id = parm.substr(0, parm.indexOf("&"));

  let gameId = localStorage.getItem("gameId");
  //}
  
  useEffect(() => {
    if (!currentQuiz) {
      setLoading(true);
      getSettings(id)
        .then((res) => {
          const { quiz_settings, categories } = res.data.data;
          const tmpCategories = {};
          categories.forEach(element => {
            tmpCategories[element.id] = element.name;
          });
          setCategoriesList(tmpCategories);
          setCurrentQuiz(quiz_settings);
          setMyQuizes({ ...myQuizes, [id]: quiz_settings });
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log("Error!", error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuiz]);

  return (
    <div>
      <div className="quiz">
        {loading && <Loading />}
        <div id="quiz-main-page">
          <h2>חידון מספר {gameId}</h2>
          <ul>
            <li onClick={() => {
              history.replace(QUIZ_SETTINGS_PATH.replace(":id", id));
            }}> לבניית החידון ורשימת המשתתפים <i class="fa fa-cogs" aria-hidden="true"></i></li>
            <li id="disable" onClick={() => {
              history.replace(QUIZ_QUESTIONS_PATH.replace(":id", id));
            }}>בניית החידון <i class="fa fa-volume-control-phone" aria-hidden="true"></i></li>
            <li id="disable" onClick={() => {
              history.replace(QUIZ_IMPORT_PATH.replace(":id", id));
            }}>העלאת נתונים מאקסל</li>
            <li id="disable" class="" onClick={() => {
              history.replace(QUIZ_GAMERS_PATH.replace(":id", id));
            }}>רשימת משתתפים <i class="fa fa-users" aria-hidden="true"></i></li>
          </ul>
          <hr></hr>
          <h2>החידון מוכן?</h2>
          <ul>
            <li class="live" onClick={() => {
              if(window.confirm('שימו לב!\n\nעד חצי שעה לפני הזמן שציינתם בהזמנה - הכניסה תתאפשר כדי שתוכלו להכיר ולהתנסות בניהול חידון,\nבמצב טסט הכניסה מוגבלת ל10 משתתפים בלבד למערכת הטלפונית.\nהאם ברצונך להיכנס כעת?')){window.location.href = '/ניהול-חידון/?q=' + id + '&game=' + gameId};
            }}>ביצוע החידון <i class="fa fa-volume-control-phone" aria-hidden="true"></i></li>
            {(currentQuiz?.remote > 0) &&
              (
              <li class="live" onClick={() => {
                if(window.confirm('מסך התצוגה יפתח בחלון חדש, באפשרותכם לגרור אותו למסך המקרן וכדומה.')){
                  window.open('/תצוגה/?q=' + id, '_blank');
              };
              }}>פתח מסך תצוגה <i class="fa fa-television" aria-hidden="true"></i></li>
            )}
          </ul>
          <ExportDialog dialogOpen={open} />
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        rtl={true}
        type="success"
      />
    </div>
  );
}
