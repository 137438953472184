import S3 from 'aws-s3';
// https://github.com/Fausto95/aws-s3

export const getS3Client = (dirName) => {
    const config = {
        bucketName: 'clickef.com-media',
        dirName,
        region: "eu-west-1",
        accessKeyId: 'AKIA2YTILSWXXZWUEPPX',
        secretAccessKey: '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',
        s3Url: "https://s3-eu-west-1.amazonaws.com/clickef.com-media"
    }
    return new S3(config);
}
