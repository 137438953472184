export enum quizType {
  BASIC_QUIZ = 0,
  PHONE_CLICK = 1,
  CLICK_4U = 2,
  CLICKEF_EVENTS = 3,
}

export enum quizCategory {
  category1 = 0,
  category2 = 1,
  category3 = 2,
  category4 = 3,
}

export interface quizes {
  id: number;
  name: string;
  type: quizType;
  subject: string;
  category_id: quizCategory;
  remote: string;
  timer_default: number; // timer = 0 - no timer
  lock_users: boolean; // quiz type 1
  with_code: boolean; // quiz type 1
  groups: Array<string>; // quiz type 2 + 3
  questionsList: []; // only ids
}
interface Answer {
  id: number;
  question_id: number;
  text: string;
  currect: number;
  image: 0;
}

interface Tag {
  tag_id: number;
  name: string;
}

export interface questions {
  id: number;
  quiz_id: number;
  type: number;
  answers_type: number;
  text: string;
  background_image: any;
  timer: number;
  weight: number;
  answers: Answer[];
  tags: Tag[];
}
