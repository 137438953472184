    import React, { useState, useRef, useEffect } from "react";
    import { Prompt } from 'react-router';
    import QuizMenu from "../Components/QuizMenu";
    import { getQuizGroups, getQuizGamers, saveQuizGamers , exportUsersQuiz} from "../utils/APIs";
    import { ToastContainer, toast } from 'react-toastify';
    import 'react-toastify/dist/ReactToastify.css';
    import "../sass/Quiz.scss";
    import "../sass/QuizGamers.scss";
    import { useQuizContext } from "../Contexts/QuizesContext";
    import { useRouteMatch, useHistory, useLocation} from "react-router-dom";
    import Loading from "../Components/Loading";
    import { QUIZ_GAMERS_PATH, QUIZ_QUESTIONS_PATH } from "./RoutesPath";
    import { ExitToApp } from "@material-ui/icons";
    import AlertDialog from "../Components/Dialog";
    import Stepper from "../Components/Stepper";
    //import SheetJSApp from "./QuizImport";
    //import Import from "./SheetJSApp";
    import XLSX from 'xlsx';
    import Tooltip from '@material-ui/core/Tooltip';
    import Typography from '@material-ui/core/Typography';
    import { withStyles } from '@material-ui/core/styles';

    export default function QuizGamers() {
        const { loading, setLoading } = useQuizContext();
        const [error, setError] = useState('');
        const { id } = useRouteMatch(QUIZ_GAMERS_PATH).params;
        const [gamers, setGamers] = useState([]);
        const [groups, setGroups] = useState([]);
        const [hasChanged, setHasChanges] = useState(false);
        const history = useHistory();
        const [open, setOpen] = React.useState();
        const [openError, setOpenError] = React.useState();
        const [gKey, setKey] = useState(0);
        const { currentQuiz, setCurrentQuiz } = useQuizContext();

        useEffect(() => {
            getQuizGroups(id).then((res) => {
            console.log(res.data);
            setGroups(res.data.groups);
            }).catch((error) => {
            console.log("Error!", error.response);
            });
            setLoading(true);
            getQuizGamers(id).then((res) => {
            setGamers(res.data.gamers_list);
            setLoading(false);
            }).catch((error) => {
            setLoading(false);
            console.log("Error!", error.response);
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        useEffect(() => {
            setTimeout(() => {
            setError('');
            }, 7000)
        }, [error])
        var aa = [];

        const deleteGamer = () => {
            setHasChanges(true);
            console.log(gKey);
            aa = gamers;
            
            if(typeof aa === 'object')
            {
                delete aa[gKey];
            }else{
                aa.splice(gKey,1);
            }
            
            setGamers({...aa});
        };

        const setGame = (key, field, val) => {
            setHasChanges(true);
            let game = gamers[key];
            game[field] = val;
            setGamers({ ...gamers, [key]: game });
        }
        const addGame = () => {
            setHasChanges(true);
            const nextId = gamers ? Object.keys(gamers).length : 0;
            setGamers({ ...gamers, [nextId]: { name: '', phone: '', group_id: 0 } });
        }

        const reload = () => {
            console.log("reload...")
            setHasChanges(true);
            getQuizGroups(id).then((res) => {
                console.log(res.data);
                setGroups(res.data.groups);
                }).catch((error) => {
                console.log("Error!", error.response);
                });
                setLoading(true);
                getQuizGamers(id).then((res) => {
                setGamers(res.data.gamers_list);
                setLoading(false);
                }).catch((error) => {
                setLoading(false);
                console.log("Error!", error.response);
                });
            console.log("End Reload!")
        }

        const onlyDigit = (val) => {
            return !val || /^\d+$/.test(val);
        }

        const isCode = (val, key) => {
            if(val == "" || !val){
                setHasChanges(true);
                toast.error('לא ציינת קוד אישי בשורה ' + (parseInt(key)+1));
            }
            
            return val;
        }

        const innerSetKey = (key) => {
            setKey(key);
            console.log("k:"+gKey);
            openDialog();
        }

        const saveGamers = () => {
            let valid = true;
            let index = 0;
            let exist = [];
            Object.keys(gamers).forEach((key) => {
                
                index++;
                if(currentQuiz?.with_code === '0') {
                    if (!gamers[key].phone || !/^\d{9,15}$/.test(gamers[key].phone)) {
                        setError('מספר טלפון תקין למשתתף הוא שדה חובה (שורה ' + index + ')');
                        valid = false;
                    }
                } else {
                    var CodeEx = !(gamers[key].code);
                    var onlyDigits = !(/^\d+$/.test(gamers[key].code));
                    //aaa = !/^\d$/.test(gamers[key].code);
                    if (CodeEx || onlyDigits) {
                        setError('קוד כניסה לא תקין (שורה ' + index + ')');
                        valid = false;
                    }

                    var position = exist.indexOf(gamers[key].code);
                    if(position >= 0)
                    {
                        setError('בשורה ' + index + ' הקשת קוד אישי שכבר קיים בשורה ' + (position+1));
                        valid = false;
                    }
                    exist[key] = gamers[key].code;
                }
            })
            if (valid) {
                saveQuizGamers(id, { gamers: gamers }).then((res) => {
                    if(JSON.stringify(res['data']).indexOf('success') > -1){
                    exportUsersQuiz(id).then((res) => {
                        if(JSON.stringify(res['data']).indexOf('success') > -1){
                        toast.success("רשימת המשתתפים נשמרה בהצלחה");
                        setHasChanges(false);
                        history.replace(QUIZ_QUESTIONS_PATH.replace(":id", id));
                        } else  {
                        toast.error(JSON.stringify(res['data']));
                        console.log(JSON.stringify(res));
                        }
                    }).catch((error) => {
                        console.log("Error!", error.response);
                        if(error.response.data["error"] === "game started"){
                            openErrorDialog();
                            toast.error("שגיאה");
                        }
                        else {
                            toast.error("שגיאה: " + error.response.data["error"]);
                        }
                    });
                    } else {
                    toast.error(JSON.stringify(res['data']));
                    console.log(JSON.stringify(res));
                    }
                });
            }
        }

        
        const openDialog = () => {
            setOpen(true);
        }

        const openErrorDialog = () => {
            setOpenError(true);
        }

        return (
            <div>
            <Prompt
                when={hasChanged}
                message="ישנם שינויים שלא נשמרו, האם אתה בטוח שברצונך לעזוב את הדף?"
            />
            <Stepper />
            <AlertDialog dialogOpen={openError} onCancel={() => { setOpen(false) }} title="לא ניתן לשמור לאחר התחלת החידון, אם התחלתם את החידון רק לצורך בדיקות ניתן לנסות לשמור שוב לאחר איפוס המשחק מפאנל ניהול החידון." />
            <AlertDialog dialogOpen={open} onOk={deleteGamer} onCancel={() => { setOpen(false) }} title="האם ברצונך למחוק את המשתמש מהרשימה?" okText="כן" />
            <div className="quiz-content">
                {loading && <Loading />}
                <div id="quiz-gamers-page">
                <SheetJSApp />
                <button className="add-game" onClick={addGame}>הוסף משתתף <i class="fa fa-user-plus" aria-hidden="true"></i>
        </button>
                <div className="error-msg">{error}</div>
                <div className="gamers-items">
                <input type="hidden" id="listtype" value={currentQuiz?.with_code}/>
                <input type="hidden" id="fileLoaded" value="0" onChange={reload}/>
                    {gamers && Object.keys(gamers).map((key) => {
                    return (
                        <div className="game-item" key={key}>
                        <div className="item"><b>{parseInt(key)+1}</b></div>
                        <div className="item"><i class="fa fa-trash" aria-hidden="true" title="מחיקת השורה" onClick={() => { innerSetKey(key) }}></i></div>
                        <div className="item">
                            <label>שם:</label>
                            <input type="text" autoFocus value={gamers[key].name} onChange={e => { setGame(key, 'name', e.target.value) }} />
                        </div>
                        {currentQuiz?.with_code === '0' ? (
                        <div className="item">
                            <label>טלפון:</label>
                            <input type="text" value={gamers[key].phone} onChange={e => { if (onlyDigit(e.target.value)) { setGame(key, 'phone', e.target.value) } else { setError('מספר טלפון לא תקין.'); setGame(key, 'phone', '') } }} />
                        </div>
                        ) : (
                        <div className="item">
                            <label>קוד כניסה:</label>
                            <input type="text" value={hasChanged ? gamers[key].code : isCode(gamers[key].code, key)} onChange={e => { if (onlyDigit(e.target.value)) { setGame(key, 'code', e.target.value) } else { setError('קוד הכניסה אינו תקין.'); setGame(key, 'code', '') } }} />
                        </div>
                        )}
                        <div id="disable" className="item">
                            <label>קבוצה:</label>
                            <select
                            name="gamers-group"
                            value={gamers[key].group_id}
                            onChange={(e) => setGame(key, 'group_id', e.target.value)}
                            >
                            <option value="0">-- בחר --</option>
                            {groups && Object.keys(groups).map((i) => {
                                return (
                                <option key={i} value={groups[i].id}>{groups[i].name}</option>
                                )
                            })}
                            </select>
                        </div>
                        <div className="item">
                            <label>קבוצה:</label>
                            <input type="text" class="mark" value={gamers[key].group} onChange={e => { setGame(key, 'group', e.target.value) }} />
                        </div>
                        </div>
                    )
                    })}
                </div>
                </div>
            </div>
            {(hasChanged === true) && ( 
                <div className="form-button">
                <button className="save-gamers" onClick={saveGamers}>שמור <i class="fa fa-floppy-o" aria-hidden="true"></i></button>
                </div>
                )}
                <div id="reload" onClick={reload}></div>
                <ToastContainer
                position="bottom-center"
                autoClose={3000}
                rtl={true}
                type="success"
                />
            </div>
        );
    }

    /*
  Simple HTML5 file drag-and-drop wrapper
  usage: <DragDropFile handleFile={handleFile}>...</DragDropFile>
    handleFile(file:File):void;
*/
class DragDropFile extends React.Component {
	constructor(props) {
		super(props);
		this.onDrop = this.onDrop.bind(this);
	};
	suppress(evt) { evt.stopPropagation(); evt.preventDefault(); };
	onDrop(evt) { evt.stopPropagation(); evt.preventDefault();
		const files = evt.dataTransfer.files;
		if(files && files[0]) this.props.handleFile(files[0]);
	};
	render() { return (
<div onDrop={this.onDrop} onDragEnter={this.suppress} onDragOver={this.suppress}>
	{this.props.children}
</div>
	); };
};

/*
  Simple HTML5 file input wrapper
  usage: <DataInput handleFile={callback} />
    handleFile(file:File):void;
*/
class DataInput extends React.Component {
	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
	};
	handleChange(e) {
		const files = e.target.files;
		const id = e.target.form.action;
		if(files && files[0]) this.props.handleFile(files[0],id);
    };
    AsDownload() {
        window.open("https://clickef.com/phone/%D7%9E%D7%A9%D7%AA%D7%AA%D7%A4%D7%99%20%D7%94%D7%97%D7%99%D7%93%D7%95%D7%9F.xlsx", "_blank");
        toast.success("הקובץ ירד למחשב בהצלחה")
    };

	render() {
        const HtmlTooltip = withStyles((theme) => ({
            tooltip: {
              backgroundColor: '#f5f5f9',
              color: 'rgba(0, 0, 0, 0.87)',
              maxWidth: 220,
              fontSize: theme.typography.pxToRem(12),
              border: '1px solid #dadde9',
              direction: "rtl",
            },
        }))(Tooltip);

		return (
			<form className="form-inline">
				<label>
					
                    <HtmlTooltip
                        title={
                        <React.Fragment>
                            <Typography color="inherit">העלאת רשימה מקובץ אקסל</Typography>
                            <em>{"שימו לב!"}</em> <b>{'כל המשתתפים הקודמים ימחקו'}</b>
                        </React.Fragment>
                        }>
                            <div className="form-group add-game add-file">
                            <span htmlFor="file"><i class="fa fa-upload" aria-hidden="true"></i></span>
                            <input type="file" className="form-control hide" id="file" accept={SheetJSFT} onChange={this.handleChange} />
					        </div>
                    </HtmlTooltip>
				</label>
                <label>
					
                    <HtmlTooltip
                        title={
                        <React.Fragment>
                            <Typography color="inherit">הורדת מבנה קובץ משתתפי חידון</Typography>
                            <em>{"הורידו מלאו והעלו בכפתור הירוק"}</em> <b>{'אין לשנות את המבנה ואת הכותרות'}</b>
                        </React.Fragment>
                        }>
                            <div className="form-group add-game add-file download">
                            <span><i class="fa fa-download" aria-hidden="true"></i></span>
                            <input type="button" className="form-control hide" id="down" onClick={this.AsDownload} />
					        </div>
                    </HtmlTooltip>
				</label>
			</form>
		);
	};
}

/* list of supported file types */
const SheetJSFT = [
	"xlsx", "xlsb", "xlsm", "xls", "xml", "csv", "txt", "ods", "fods", "uos", "sylk", "dif", "dbf", "prn", "qpw", "123", "wb*", "wq*", "html", "htm"
].map(function(x) { return "." + x; }).join(",");

/* xlsx.js (C) 2013-present  SheetJS -- http://sheetjs.com */
/* Notes:
   - usage: `ReactDOM.render( <SheetJSApp />, document.getElementById('app') );`
   - xlsx.full.min.js is loaded in the head of the HTML page
   - this script should be referenced with type="text/babel"
   - babel.js in-browser transpiler should be loaded before this script
*/

class SheetJSApp extends React.Component {
    
	handleFile(file/*:File*/,id) {
		/* Boilerplate to set up FileReader */
		const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        
		reader.onload = (e) => {
			/* Parse data */
			const bstr = e.target.result;
			const wb = XLSX.read(bstr, {type:rABS ? 'binary' : 'array'});
			/* Get first worksheet */
			const wsname = wb.SheetNames[0];
			const ws = wb.Sheets[wsname];
			/* Convert array of arrays */
			const data = XLSX.utils.sheet_to_json(ws, {header:1});
			
			console.log(ws);
			console.log(ws.A1.w);
			
            var alpha = new Array("A","B","C","D","E");
            var duplicate = [];
            var countCodes = 0;
            var isNoDig = false;
            var errorRow = 0;
            var errorMsg = "";
            var isPhoneOk = true;
            
            var nameCol = 1;
            var phoneCol = 2;
            var codeCol = 3;
            var groupCol = 4;
            
            var row = 0;
			var toJson = [];
            var last = ws["!ref"].substring(ws["!ref"].indexOf(":")+1);
            var type = document.getElementById("listtype").defaultValue;
			for (let gamerRow = 3; gamerRow <= last.match(/\d/g).join(''); gamerRow++) {	
				if(alpha[nameCol]+gamerRow == last) {
					break;
				} else {
					if(alpha[nameCol]+gamerRow in ws || alpha[phoneCol]+gamerRow in ws || alpha[codeCol]+gamerRow in ws) {
                        var gmr = {};

                        //check if code empty
                        if(alpha[codeCol]+gamerRow in ws){
                            if((/^\d+$/.test(ws[alpha[codeCol]+gamerRow].v))){
                                if(duplicate.includes(ws[alpha[codeCol]+gamerRow].v)){
                                    //isDuplicate = true;
                                    errorRow = row+1;
                                    errorMsg = "ברשומה " + errorRow + " הקשת קוד אישי שכבר קיים בשורה אחרת";
                                } else {
                                    gmr["code"] = (alpha[codeCol]+gamerRow in ws) ? ws[alpha[codeCol]+gamerRow].v :"";
                                    duplicate.push(ws[alpha[codeCol]+gamerRow].v);
                                }
                            } else {
                                //isNoDig = true;
                                errorRow = row+1;
                                errorMsg = "ברשומה " + errorRow + " הקוד אישי לא תקין, חייב להיות מספר";
                            }
                        } else if(type == "1"){
                            countCodes++
                            errorMsg = "קוד אישי חובה לפי מה שהגדרתם בשלב הקודם, לרשומה " + countCodes + " לא הוגדר קוד בקובץ";
                        }

                        // check if phone empty
                        if(alpha[phoneCol]+gamerRow in ws){
                            var phoneRep = ws[alpha[phoneCol]+gamerRow].w;
                            phoneRep = phoneRep.replace("-","");
                            phoneRep = phoneRep.replace("+","");
                            if((/^\d+$/.test(phoneRep)) && (/^\d{9,15}$/.test(phoneRep))){
                                gmr["phone"] = ws[alpha[phoneCol]+gamerRow].t === "n" ? "0"+phoneRep : phoneRep;
                            } else {
                                errorRow = row+1;
                                errorMsg = "ברשומה " + errorRow + " מספר טלפון לא תקין";
                            }
                        }
                        
                        // check if group empty
                        if(alpha[groupCol]+gamerRow in ws){
                            gmr["group"] = (!ws[alpha[groupCol]+gamerRow].t) ? "" : ws[alpha[groupCol]+gamerRow].w;
                        }

                        // add name 
						gmr["name"] = (alpha[nameCol]+gamerRow in ws) ? ws[alpha[nameCol]+gamerRow].v : "";

						
						toJson.push(gmr);
						
						//this.setHasChanges(true);
						//this.setGamers({ ...this.gamers, [row]: { name: gmr["name"], phone: gmr["phone"], group_id: 0 } });
					} else {
						break;
					}
				}
				row++;
			}
			//setGamers(toJson);
            console.log(toJson);
            
            let isData = toJson.length;

            if(toJson.length > 0 && errorRow == 0 && countCodes == 0){
                id = id.split("/");
                id = id[id.length-2];
                saveQuizGamers(id, { gamers: toJson }).then((res) => {
                    if(JSON.stringify(res['data']).indexOf('success') > -1){
                        
                        
                        exportUsersQuiz(id).then((res) => {
                            if(JSON.stringify(res['data']).indexOf('success') > -1){
                            toast.success("רשימת המשתתפים פוענחה והועלתה בהצלחה, לא לשכוח לשמור!");
                            
                            //history.replace(QUIZ_QUESTIONS_PATH.replace(":id", id));
                            
                            document.getElementById("reload").click();
                            
                            

                            } else  {
                            toast.error(JSON.stringify(res['data']));
                            console.log(JSON.stringify(res));
                            }
                        }).catch((error) => {
                            console.log("Error!", error);
                            toast.error("תקלה בתקשורת, בדקו את החיבור שלכם לרשת");
                        });
                        
                    } else {
                        toast.error(JSON.stringify(res['data']));
                        console.log(JSON.stringify(res));
                    }
                });
            } else {
                toast.error(errorMsg + "\nהקובץ לא הועלה! נא לתקן, ונסו להעלות מחדש.");    
            }

            document.getElementById("file").value = "";
		};

        if(rABS) reader.readAsBinaryString(file); else reader.readAsArrayBuffer(file);
        
	};

	render() {
		return (
			<DragDropFile handleFile={this.handleFile}>
				<div className="row">
					<div>
						<DataInput handleFile={this.handleFile} />
					</div>
				</div>
			</DragDropFile>
		);
	};
};
