export const MY_QUIZS_PATH = "/my-quizes";
export const QUIZ_PATH = "/my-quizes/:id";
export const QUIZ_SETTINGS_PATH = "/my-quizes/:id/settings";
export const QUIZ_GAMERS_PATH = "/my-quizes/:id/gamers";
export const QUIZ_IMPORT_PATH = "/my-quizes/:id/import";
export const QUIZ_ADD_SETTINGS_PATH = "/my-quizes/add/settings";
export const QUIZ_QUESTIONS_PATH = "/my-quizes/:id/questions";
export const QUIZ_ADD_QUESTIONS = "/my-quizes/:id/questions/add";
export const STOCK_PATH = "/stock/:id";
export const QUIZ_EDIT_QUESTIONS = "/my-quizes/:id/questions/:questionId";
