import React, { useState, useEffect, useRef } from "react";
import { Collapse } from "@material-ui/core";
import { useRouteMatch } from 'react-router-dom';
import { useQuestionContext } from "../Contexts/QuestionsContext";
import { Radio } from "pretty-checkbox-react";
import { QUIZ_EDIT_QUESTIONS } from "../Routes/RoutesPath";
import ImgUploader from "./ImageUploader";
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { styled } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

export default function QuestionAnswers() {
    const [answersOpen, setAnswersOpen] = useState(true);
    const [lastChanged, setlastChanged] = useState(null);
    const { currentQuestion, setCurrentQuestion } = useQuestionContext();
    const { questionId } = useRouteMatch(QUIZ_EDIT_QUESTIONS).params;
    const itemsRef = useRef([]);
    const myref = useRef(null);


    const setCurrectAnswer = (index) => {
        setCurrentQuestion({
            ...currentQuestion, answers: Object.keys(currentQuestion.answers).map((key) => {
                return {
                    ...currentQuestion.answers[key], currect: key == index ? "1" : "0"
                }
            }), answers_changes: 1, hasChanged : true
        });
    }

    const setAnswerText = (e, key) => {
        const { value } = e.nativeEvent.target;
        const currentAnswer = currentQuestion.answers[key];
        setCurrentQuestion({
            ...currentQuestion,
            answers: { ...currentQuestion.answers, [key]: { ...currentAnswer, text: value } },
            answers_changes: 1, hasChanged : true
        });
        setlastChanged(key);
        //e.nativeEvent.target.autoFocus = true;
    }

    const uploadFromUrl = (url, key) => {
        const currentAnswer = currentQuestion.answers[key];
        setCurrentQuestion({
            ...currentQuestion,
            answers: { ...currentQuestion.answers, [key]: { ...currentAnswer, image: url, imageChanged: false } },
            answers_changes: 1, hasChanged : true
        });

    }

    const addAnswer = () => {
        const nextId = !currentQuestion.answers || currentQuestion.answers === {} ? 0 :
            Object.keys(currentQuestion.answers).length + 1
        setCurrentQuestion({
            ...currentQuestion, answers: {
                ...currentQuestion.answers, [nextId]: {
                    text: "", currect: nextId == 0 ? "1" : "0", image: null
                }
            }, answers_changes: 1, hasChanged : true
        });
    }

    const setAnswerImage = (mediaObject, key) => {
        setCurrentQuestion({
            ...currentQuestion, answers: {
                ...currentQuestion.answers, [key]: {
                    ...currentQuestion.answers[key], mediaObject, imageChanged: true
                }
            }, answers_changes: 1, hasChanged : true
        });
    }

    const removeImage = (key) => {
        setCurrentQuestion({
            ...currentQuestion, answers: {
                ...currentQuestion.answers, [key]: {
                    ...currentQuestion.answers[key], mediaObject: {}, imageChanged: true
                }
            }, answers_changes: 1, hasChanged : true
        });
    }

    useEffect(() => {
        if (questionId === "add") {
            setCurrentQuestion({ ...currentQuestion, answers_type: 1 })
        }
    }, []);

    const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
          backgroundColor: '#f5f5f9',
          color: 'rgba(0, 0, 0, 0.87)',
          maxWidth: 220,
          fontSize: theme.typography.pxToRem(12),
          border: '1px solid #dadde9',
        },
    }))(Tooltip);

    const MyButton = styled(Button)({
        background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
        border: 0,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'white',
        height: 48,
        padding: '0 30px',
      });

    return (
        <div className="question-answers fieldset-wrap">
            <div className={"answers-title fieldset-title" + (answersOpen ? " open" : "")} onClick={() => setAnswersOpen(!answersOpen)} title="סמנו את התשובה הנכונה בטריוויה">תשובות</div>
            <Collapse in={answersOpen} className="fieldset-content">
                <div className="answers-items">
                    {currentQuestion?.answers && Object.keys(currentQuestion?.answers).map((key, index) => {
                        const answer = currentQuestion.answers[key];
                        return (
                            <div className="answer-item">
                                {currentQuestion?.answers_type == 1 && (
                                    <Radio name="current" ref={el => itemsRef.current[index] = el}
                                        checked={answer.currect == '1'} onChange={() => setCurrectAnswer(key)} />
                                )}
                                {currentQuestion?.answers_type == 1 ? (
                                <HtmlTooltip
                                    title={
                                    <React.Fragment>
                                        <Typography color="inherit">סמנו את התשובה הנכונה</Typography>
                                        <em>{"זאת התשובה הנכונה?"}</em> <b>{'לחצו על העיגול'}</b>
                                    </React.Fragment>
                                    }
                                >
                                    {lastChanged == key ? <input autoFocus className="ans-input" maxLength="65" type="text" id={`input${key}`} value={answer.text} onChange={e => { setAnswerText(e, key) }} />:
                                    <input className="ans-input" maxLength="65" type="text" id={`input${key}`} value={answer.text} onChange={e => { setAnswerText(e, key) }} />}
                                    
                                </HtmlTooltip>
                                ) : (<input maxLength="65" type="text" class="ans-input full" id={`input${key}`} value={answer.text} onChange={e => { setAnswerText(e, key) }} />)}
                                {/*currentQuestion?.type == 3 && (
                                    <ImgUploader
                                        setPicture={(mediaObject) => setAnswerImage(mediaObject, key)}
                                        hasImage={(answer.image && answer.image !== "") || answer.mediaObject}
                                        removeImage={() => removeImage(key)}
                                        background_image={answer.image}
                                        imageChanged={answer.imageChanged}
                                        type={currentQuestion?.type}
                                        uploadFromUrl={(url) => uploadFromUrl(url, key)}
                                        mediaObject={answer.mediaObject}
                                    />
                                )*/}
                            </div>
                        )
                    })}
                </div>
                {(!currentQuestion?.answers || Object.keys(currentQuestion?.answers).length < 4) && (
                    <MyButton ref={myref} onClick={addAnswer}>+</MyButton>
                )}
            </Collapse>
        </div>
    );
}