import React, { useState, useEffect } from 'react';
import { getTags } from '../../utils/APIs';
import { useStockContext } from '../../Contexts/StockContext';

export default function StockSearchLock() {

    const { searchFreeText, setSearchFreeText, searchCategories, setSearchCategories, searchTags, setSearchTags, setSearchClicked } = useStockContext();
    const [tags, setTags] = useState([]);
    const [categories, setCategories] = useState([]);
    const [tagName, setTagName] = useState('');
    const [categoryName, setCategoryName] = useState('');

    useEffect(() => {
        getTags().then(res => {
            const { tags, categories } = res.data;
            setTags(tags);
            setCategories(categories);
            Object.keys(categories).forEach(key => {
                if (searchCategories === categories[key].id) {
                    setCategoryName(categories[key].name);
                }
            });
            Object.keys(tags).forEach(key => {
                if (searchTags.indexOf(tags[key].id) > -1) {
                    setTagName(tags[key].name);
                }
            });
        });
    }, [])

    return (
        <div id="stock-search-lock">
            <div className="row row-1">
                <div>
                    <span>תוצאות חיפוש עבור</span>
                    <span><b>{searchFreeText}</b></span>
                </div>
            </div>
            <div className="row row-2">
                <div>
                    {categoryName && (
                        <span>
                            <span>קטגוריה:</span>
                            <span><b>{categoryName}</b></span>
                        </span>
                    )}
                    {tagName && (
                        <span>
                            <span>תגית:</span>
                            <span><b>{tagName}</b></span>
                        </span>
                    )}
                </div>
                <div><button onClick={() => { setSearchFreeText(''); setSearchCategories(null); setSearchTags([]); setSearchClicked(false) }}>נקה חיפוש</button></div>
            </div>
        </div>
    );
}