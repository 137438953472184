import React, { useEffect, useState, ReactDOM } from "react";
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import VideoLabelIcon from '@material-ui/icons/Dvr';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import { QUIZ_SETTINGS_PATH, QUIZ_EDIT_QUESTIONS, QUIZ_ADD_QUESTIONS, QUIZ_QUESTIONS_PATH, QUIZ_GAMERS_PATH, QUIZ_PATH, QUIZ_IMPORT_PATH,MY_QUIZS_PATH } from "../Routes/RoutesPath";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import { useQuizContext } from "../Contexts/QuizesContext";
import { getSettings } from "../utils/APIs";
import { useQuestionContext } from "../Contexts/QuestionsContext";
import { getQuizDetails } from "../utils/APIs";
import { styled } from '@material-ui/core/styles';

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);


const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <VideoLabelIcon />,
    4: <InsertInvitationIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    border: 'groove',
    padding:'10px',
    textAlign:'center',
  },
  button: {
    marginRight: theme.spacing(1),

  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  Typography:{
      body1:{
        //textAlign: 'center',
        fontWeight: 800
        //fontSize: 'xx-large',
      }
  }
}));

function getSteps() {
  return ['settings', 'gamers', 'questions'];
}

function getStepsHeb() {
  return ['הגדרות חידון', 'משתתפים', 'שאלות לחידון','סקירה'];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return 'הגדרות חידון';
    case 1:
      return 'משתתפים';
    case 2:
      return 'שאלות לחידון';
    case 3:
        return 'סיכום';
    default:
      return 'סיום';
  }
}

function clean(sumData) {
    if(typeof document.getElementsByClassName("quiz-content")[0] !== 'undefined'){
        document.getElementsByClassName("quiz-content")[0].remove();
    }

    if(sumData.locked === "true"){
        if(sumData.code === "true"){
            if(sumData.gcode == 0) {
                return 0;
            } else if(sumData.g_sum > sumData.gcode) {
                return sumData.g_sum - sumData.gcode;
            }
        } else {
            if(sumData.gphone == 0) {
                return 0;
            } else if(sumData.g_sum > sumData.gphone) {
                return sumData.g_sum - sumData.gphone;
            }
        }
    }

    return sumData.g_sum;
}

// eslint-disable-next-line react-hooks/exhaustive-deps
export default function CustomizedSteppers() {
    const [sumData, setSum] = useState();
    const getPath = (path) => {
        if (path.endsWith("/questions")) {
          return QUIZ_QUESTIONS_PATH;
        }
        if (path.endsWith("/gamers")) {
          return QUIZ_GAMERS_PATH;
        }
        if (path.endsWith("/settings")) {
          return QUIZ_SETTINGS_PATH;
        }
        return QUIZ_SETTINGS_PATH;
    }

    const getPosition = (path) => {
        if (path.endsWith("/questions")) {
            
            return 2;
        }
        if (path.endsWith("/gamers")) {
            
            return 1;
        }
        if (path.endsWith("/settings")) {
            
            return 0;
        }
        
        return 0;
    }
    
    const location = useLocation();
    const history = useHistory();
    const { id } = useRouteMatch(getPath(location.pathname)).params;
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(getPosition(location.pathname));
    const { currentQuiz, setCategoriesList, setLoading, setCurrentQuiz, setMyQuizes, myQuizes } = useQuizContext();
    const steps = getSteps();
    const HebSteps = getStepsHeb();
    useEffect(() => {
        console.log(currentQuiz);
      if (!currentQuiz || currentQuiz.id !== id) {
        setLoading(true);
        getSettings(id)
          .then((res) => {
            const { quiz_settings, categories } = res.data.data;
            const tmpCategories = {};
            categories.forEach(element => {
              tmpCategories[element.id] = element.name;
            });
            setCategoriesList(tmpCategories);
            setCurrentQuiz(quiz_settings);
            setMyQuizes({ ...myQuizes, [id]: quiz_settings });
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            console.log("Error!", error);
          });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentQuiz]);
    
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);        
        if(activeStep < steps.length-1){
            history.replace(location.pathname.replace(steps[activeStep], steps[activeStep+1]));
        } else {
            {getData(id)}
        }
    };

    const handleBack = (jump = 1) => {
        if(typeof jump == 'object'){
            jump = 1;
        }
        setActiveStep((prevActiveStep) => prevActiveStep - parseInt(jump));
        history.replace(location.pathname.replace(steps[(activeStep-(jump-1))], steps[(activeStep-parseInt(jump))]));
    };

    const handleExit = () => {
        history.replace(MY_QUIZS_PATH);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const getData = (id) => {
        getQuizDetails(id).then((res) => {
            setSum({...res.data.quiz});
        });
    };

    const { questions } = useQuestionContext();
    let count = null;

    const cleanPlus = (data) => {
        count = clean(data);
        return count;
    }

    const MyButton = styled(Button)({
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      border: 0,
      borderRadius: 3,
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      color: 'white',
      height: 48,
      padding: '0 30px',
    });

    const gameId = localStorage.getItem("gameId");

    const goToGame = () => {
        window.location.href = '/ניהול-חידון/?q=' + id + '&game=' + gameId
    }

    return (
        <div className={classes.root}>
        <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
            {HebSteps.map((label) => (
            <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
            </Step>
            ))}
        </Stepper>
        <div>
            {activeStep === HebSteps.length-1 ? (
            <div>
                <Typography className={classes.instructions}>
                {(typeof sumData !== 'undefined') && (cleanPlus(sumData) === 0 ? handleBack(2) : true) && (
                    <div class="overwiew">
                        <h2>סיימנו להכין את החידון!</h2>
                        <p><u>אז הנה סיכום מה שהכנת, וגם מה עושים הלאה:</u></p>
                        חידון מספר <strong>{sumData.quiz}</strong><br></br>
                        יתקיים ב <strong>{sumData.start}</strong>
                        <br></br><br></br>
                        בחידון יש <strong>{sumData.q_sum}</strong> שאלות
                        <br></br>
                        ו<strong>{sumData.g_sum}</strong> משתתפים רשומים.
                        <br></br><br></br>
                        ניהול החידון מתבצע הן טלפוני והן באמצעות מסך 'ביצוע החידון' שראיתם בתחילת התהליך.<br></br>
                        מספר הטלפון לניהול החידון למנחה בלבד <strong>{sumData.admin_line}</strong><br></br>
                        סיסמת המנחה שבחרתם לטלפון <strong>{sumData.admin_pass}</strong>
                        <br></br><br></br>
                        המשתתפים מחייגים לטלפון מס' <strong>{sumData.users_line}</strong> ומקישים את מס' החדר שהוא {sumData.quiz} ולאחריו #<br></br>
                        {console.log(sumData)}
                        הגדרתם שהחידון <strong>{(sumData.locked === "true" ? "נעול לכולם למעט את מי שרשמתם":"פתוח לכולם ולא רק למי שרשמתם")}</strong>.
                        <br></br>
                        {(sumData.locked === "true" ? "המשתתפים יזוהו בהתחברות כפי שקבעתם באמצעות "+(sumData.code === "true" ? "הקוד האישי" : "מספר הטלפון")+".":"")}
                        <br></br>
                        <div className="red">{(count < sumData.g_sum ? "ל" + count + " משתמשים לא הוגדר אפשרות כניסה והם לא יוכלו להתחבר! ":"")}</div>
                        <br></br>
                        <strong>שימו לב! </strong>
                        ניתן לחזור ולערוך את השאלות ורשימת המשתתפים עד חצי שעה לפני החידון.<br></br>
                        ניתן לבצע טסט על המערכת עד 10 משתתפים בקו עד חצי שעה לפני החידון.<br></br>
                        <strong>נ.ב. </strong>בסיום הטסט לזכור לאפס את הטסטים שעשיתם, הכנו לכם שם כפתור 'איפוס'.
                        <br></br><br></br>
                        אנחנו ממליצים שתשמרו אצלכם עותק מהסיכום הזה שתהיו מאורגנים עם כל הפרטים.<br></br>
                        בכל מקרה עד שעת החידון ניתן לחזור לכאן ולראות את כל הפרטים.<br></br>
                        שמחים שאתם פה איתנו, <strong>צוות הקליכיף.</strong>
                    </div>
                )}
                </Typography>
                <MyButton onClick={goToGame}>כניסה לחידון</MyButton>
                <Button onClick={handleExit} className={classes.button}>
                יציאה
                </Button>
            </div>
            ) : (
            <div>
                <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                    חזרה
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                >
                    {activeStep === HebSteps.length - 1 ? 'סיום' : 'הבא'}
                </Button>
            </div>
            )}
        </div>
        </div>
    );
}