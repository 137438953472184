import React from "react";

export default function StockQuestionTeaser({ question }) {
  
  return (
    <div className={"question question-" + question.id}>
      <i class="fa fa-hand-paper-o hand" aria-hidden="true"></i>
      <div className="question-title">{question.text}</div>
      <div className={"question-answers-type answers-type-" + question.answers_type}>
        {
          {
            1: "טריוויה",
            2: "סקר",
            3: "מדיה",
          }[question.answers_type]
        }
      </div>
    </div>
  );
}
