import React, { useState } from "react";

export const createStockContextValue = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [index, setIndex] = useState(0);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [questions, setQuestions] = useState([]);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [searchFreeText, setSearchFreeText] = useState('');
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [searchCategories, setSearchCategories] = useState(null);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [searchTags, setSearchTags] = useState([]);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [searchClicked, setSearchClicked] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [questionsChoose, setQuestionsChoose] = useState([]);


  return {
    index,
    setIndex,
    questions,
    setQuestions,
    searchFreeText,
    setSearchFreeText,
    searchCategories,
    setSearchCategories,
    searchTags,
    setSearchTags,
    searchClicked, 
    setSearchClicked,
    questionsChoose, 
    setQuestionsChoose
  };
};

export const StockContext = React.createContext(null);

export const StockContextProvider = (props) => {
  const value = createStockContextValue();

  return (
    <StockContext.Provider value={value}>
      {props.children}
    </StockContext.Provider>
  );
};

export const useStockContext = () => React.useContext(StockContext);
