import React from "react";
import { useHistory } from "react-router-dom";
import { QUIZ_PATH } from "../Routes/RoutesPath";
import { useQuizContext } from "../Contexts/QuizesContext";

export default function QuizItem({ id }) {
  const { myQuizes } = useQuizContext();

  const history = useHistory();
  const currentQuiz = myQuizes[id];
  return (
    <li
      onClick={() => {
        window.localStorage.setItem("gameId", currentQuiz.game_id);
        history.replace(QUIZ_PATH.replace(":id", id));
      }}
    >
      {currentQuiz.name}<br></br><small>[חידון מס' {currentQuiz.game_id}]</small>
      {console.log(myQuizes[id])}
    </li>
  );
}
