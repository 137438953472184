import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { QUIZ_EDIT_QUESTIONS, QUIZ_QUESTIONS_PATH } from "../Routes/RoutesPath";
import { deleteQuestion } from "../utils/APIs";
import AlertDialog from "./Dialog";
import { useQuestionContext } from "../Contexts/QuestionsContext";

export default function QuestionTeaser({ question, action, index }) {
  const history = useHistory(question.id);
  const [open, setOpen] = React.useState();
  const { id } = useRouteMatch(QUIZ_QUESTIONS_PATH).params;

  const { questions, setQuestions } = useQuestionContext();
  const editQuestion = () => {
    history.push(QUIZ_EDIT_QUESTIONS.replace(":id", id).replace(":questionId", question.id));
  };

  const deleteQuest = () => {
    deleteQuestion(question.id).then((res) => {
      const i = questions.indexOf(questions.find(item => item.id === question.id))
      const quest = questions.slice(0);
      quest.splice(i, 1)
      setQuestions(quest);
    });
  };

  const openDialog = () => {
    setOpen(true);
  }
  return (
    <div className={"question question-" + question.id} title="גרור לשינוי סדר">
      <div className="question-index">{index}</div>
      <div className="question-title" onClick={action && (editQuestion)}>{question.text}</div>
      <div className={"question-answers-type answers-type-" + question.answers_type}>
        {
          {
            1: "טריוויה",
            2: "סקר",
            3: "מדיה",
          }[question.answers_type]
        }
      </div>
      {action && (
        <div className="actions">
          <div className="btn edit" onClick={editQuestion}>ערוך</div>
          <div className="btn delete" onClick={openDialog}>מחק</div>
        </div>
      )}
      <AlertDialog dialogOpen={open} onOk={deleteQuest} onCancel={() => { setOpen(false) }} title="האם ברצונך למחוק את השאלה?" okText="מחק" />
    </div>
  );
}
