import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import "../../sass/Question.scss";
import { getStockList } from '../../utils/APIs';
import { useStockContext } from '../../Contexts/StockContext';
import StockQuestionTeaser from '../../Components/StockQuestionTeaser';

export default function StockQuestions() {

    const [hasMore, setHasMore] = useState(true);
    const [load, setLoad] = useState(0);
    const { index, setIndex, questions, setQuestions, searchClicked, searchFreeText, searchCategories, searchTags ,questionsChoose, setQuestionsChoose } = useStockContext();

    const getStockListFromServer = () => {
        const params = {
            free_text: searchFreeText,
            category_id: searchCategories,
            tags: searchTags
        };
        getStockList(index, params).then((res) => {
            if (res.data.data.stock_question_list.length === 0) {
                setHasMore(false);
            } else {
                questions.push(...res.data.data.stock_question_list)
                setQuestions(questions);
                setIndex(index + 1);
            }
        }).catch((error) => {
            console.log(error);
        });
    }
    useEffect(() => {
        getStockListFromServer();
    }, [load])

    useEffect(() => {
        setQuestions([]);
        setIndex(0);
        //setQuestionsChoose([]);
        setLoad(load + 1);
    }, [searchClicked])

    const checkQuestion = (e) => {
        if(e.target.checked){
            setQuestionsChoose([...questionsChoose, e.target.value]);
        }else{
            const newList = questionsChoose.filter((item) => item !== e.target.value);
            setQuestionsChoose(newList);
        }
    };

    return (
        <InfiniteScroll
            dataLength={questions.length}
            next={getStockListFromServer}
            hasMore={hasMore}
            loader={<h4>טוען...</h4>}
            height={"calc(100vh - 280px)"}
            style={{
                padding: 20,
                overflow: "auto",
                border: "2px solid #e34ed8",
            }}
            endMessage={
                <p style={{ textAlign: 'center' }}>
                    <b>סוף רשימה</b>
                </p>
            }
            className={"stock-question questions-list"}
        >
            {questions.map((question) => (
                <label style={{ flexDirection: "row", display: 'flex', alignItems: 'center' }}>
                    <input type="checkbox" value={question.id} checked={questionsChoose.indexOf(question.id) > -1 } onChange={checkQuestion}/>
                    <StockQuestionTeaser question={question} />
                </label>
            ))}
        </InfiniteScroll>
    );
}



