import React from "react";
import { Prompt } from 'react-router';
import { useRouteMatch } from 'react-router-dom'
import { useQuestionContext } from "../Contexts/QuestionsContext";
import { getQuestions, reorder } from "../utils/APIs";
import { QUIZ_QUESTIONS_PATH } from "../Routes/RoutesPath";
import '../sass/Question.scss'

const SaveOrder = () => {

    const { orderChanged, setOrderChanged, questions, setQuestions } = useQuestionContext();
    const { id } = useRouteMatch(QUIZ_QUESTIONS_PATH).params;

    const hideBanner = () => {
        setOrderChanged(false);
    }
    const saveReorder = () => {
        reorder(id, { questions_weight: questions.map(item => Number(item.id)) }).then((res) => {
            hideBanner();
        })
            .catch((error) => {
                console.log('Error', error);
            })
    }

    const reset = () => {
        getQuestions(id).then((res) => {
            const tmp = {};
            const { question_list } = res.data.data;
            question_list.forEach((element) => {
                tmp[element.id] = element;
            });
            setQuestions(question_list)
            hideBanner();
        })
    }

    return (
        <>
            <Prompt
                when={orderChanged}
                message="ישנם שינויים שלא נשמרו, האם אתה בטוח שברצונך לעזוב את הדף?"
            />
            {orderChanged && <div className="reorder">סדר השאלות השתנה  <button className="reorderButton" onClick={saveReorder}>שמור <i class="fa fa-floppy-o" aria-hidden="true"></i></button> <button className="reorderButton" onClick={reset}>אפס</button></div>}
        </>
    );
}

export default SaveOrder;