import React, { useState, useEffect } from "react";
import { Prompt } from 'react-router';

import "../sass/Question.scss";
import { useQuestionContext } from "../Contexts/QuestionsContext";
import { getQuestionSettings, addQuestions, editQuestions } from "../utils/APIs";
import { useRouteMatch, useHistory } from "react-router-dom";
import { QUIZ_EDIT_QUESTIONS } from "./RoutesPath";
import QuizMenu from "../Components/QuizMenu";
import QuestionAnswers from "../Components/QuestionAnswers";
import QuestionTimer from "../Components/OuestionTimer";
import { useQuizContext } from "../Contexts/QuizesContext";
import Loading from "../Components/Loading";
import Autocomplete from "../Components/Autocomplete";
import ImgUploader from "../Components/ImageUploader";
import { getS3Client } from "../utils/S3Config";
import { useUserContext } from "../Contexts/UserContext";
import Stepper from "../Components/Stepper";

export default function EditQuestion() {
  const { currentQuestion, setCurrentQuestion, removedTags, addedTags } = useQuestionContext();
  const { loading, setLoading } = useQuizContext();
  const { id, questionId } = useRouteMatch(QUIZ_EDIT_QUESTIONS).params;
  const { email } = useUserContext();
  const history = useHistory();
  const updateQuestion = (field, value) => {
    const imageChanged = field === "mediaObject"
    setCurrentQuestion({ ...currentQuestion, [field]: value, imageChanged , hasChanged : true});
  };
  const [ansError, setError] = useState('');


  async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  const getAnswersWithImages = async () => {
    const tmpAnswers = [];
    currentQuestion.answers && await asyncForEach(Object.keys(currentQuestion.answers), async key => {
      const currentAnswer = currentQuestion.answers[key];
      if (currentAnswer.text && currentAnswer.text !== "") {
        const imgUrl = await uploadImage({ ...currentAnswer, background_image: currentAnswer.image })
        const { image, imageChanged, mediaObject, ...tmpAns } = currentQuestion.answers[key];
        tmpAnswers.push({ id: key, ...tmpAns, image: imgUrl })
      }
    });
    return tmpAnswers;

  }

  const uploadImage = (imgItem) => {
    if (!imgItem.imageChanged) {
      return Promise.resolve(() => { }).then(() => {
        return imgItem.background_image
      });
    }
    const urlArray = imgItem.background_image && imgItem.background_image.split('/');
    const fileToDelete = urlArray && urlArray[urlArray.length - 1];
    if (!imgItem.mediaObject || imgItem.mediaObject == {}) { // no new image but image changed. means - image deleted
      return getS3Client(`${email.replace('@', '-')}/question${id}`).deleteFile(fileToDelete)
        .then(response => {
          console.log('response', response)
        })
        .catch(err => console.error('delete image error', err))
    }
    else {
      if (imgItem.background_image) { //Replace image, so delete old one
        // return getS3Client(`${email.replace('@', '-')}/question${id}`).deleteFile(fileToDelete)
        //   .then(response => {
        //     console.log('response', response)
        //   })
        //   .catch(err => console.error('delete image error', err))
      }
      return getS3Client(`${email.replace('@', '-')}/question${id}`)
        .uploadFile(imgItem.mediaObject)
        .then(data => {
          return data.location;
        })
        .catch(err => console.error('uploadImage err', err))
    }
  }

  const editQuest = () => {
    setLoading(true);
    uploadImage(currentQuestion).then(async (questionImageUrl) => {
      const tmpAnswers = await getAnswersWithImages();
      var corr=[];
      for (let index = 0; index < tmpAnswers.length; index++) {
        corr[index] = tmpAnswers[index]["currect"];
      }
      if(!corr.includes("1") && currentQuestion.type == '1'){
        setError('חובה לסמן את התשובה הנכונה');
        console.log(tmpAnswers);
        setLoading(false);
        return false;
      }
      if (tmpAnswers.length < 2) {
        setError('חובה להגדיר לפחות 2 תשובות');
        console.log(tmpAnswers);
        setLoading(false);
        return false;
      } else if(currentQuestion.text == "") {
        setError('שכחתם למלא את השאלה עצמה');
        console.log(currentQuestion.text);
        setLoading(false);
        return false;
      } else {
        editQuestions(questionId, { ...currentQuestion, background_image: questionImageUrl, answers: tmpAnswers, tags_added: addedTags, tags_remove: removedTags }).then((res) => {
          setLoading(false);
          setCurrentQuestion({ hasChanged : false })
          history.goBack();
        }).catch((error) => {
          console.error("Error!", error.response);
          setLoading(false);
        })
      }
    })

  };

  const addQuest = () => { //HERE
    uploadImage(currentQuestion).then(async (questionImageUrl) => {
      const tmpAnswers = await getAnswersWithImages();
      var corr=[];
      for (let index = 0; index < tmpAnswers.length; index++) {
        corr[index] = tmpAnswers[index]["currect"];
      }
      if(!corr.includes("1") && currentQuestion.type == '1'){
        setError('חובה לסמן את התשובה הנכונה');
        console.log(tmpAnswers);
        setLoading(false);
        return false;
      }
      if (tmpAnswers.length < 2) {
        setError('חובה להגדיר לפחות 2 תשובות');
        console.log(tmpAnswers);
        setLoading(false);
        return false;
      } else if(currentQuestion.text == "") {
        setError('שכחתם למלא את השאלה עצמה');
        console.log(currentQuestion.text);
        setLoading(false);
        return false;
      } else {
        setLoading(true);
        addQuestions(id, { ...currentQuestion, background_image: questionImageUrl, tags_added: addedTags, tags_remove: removedTags, answers: tmpAnswers }).then((res) => {
          setLoading(false);
          setCurrentQuestion({ hasChanged : false })
          history.goBack();
        });
      }
    });
  };

  const uploadFromUrl = url => {
    setCurrentQuestion({ imageChanged: false, background_image: url , hasChanged : true})
  }

  useEffect(() => {
    if (questionId !== "add") {
      setLoading(true);
      getQuestionSettings(questionId).then((res) => {
        const { question_settings } = res.data.data;
        const { answers } = question_settings;
        const tmpAnswers = {}
        answers.forEach(element => {
          const { id, ...rest } = element
          tmpAnswers[id] = rest;
        });
        setCurrentQuestion({ ...question_settings, answers: tmpAnswers });
        setLoading(false);
        document.getElementById(`answers-type-${question_settings.answers_type}`).click();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const hasImage = (currentQuestion.background_image && currentQuestion.background_image !== "") || currentQuestion.mediaObject;
  return (
    <div id="question-page">
      <Prompt
        when={currentQuestion?.hasChanged === true}
        message="ישנם שינויים שלא נשמרו, האם אתה בטוח שברצונך לעזוב את הדף?"
      />
      
      {loading && <Loading />}
      <div className="quiz-content">
        <div className="back" onClick={() => { history.goBack(); }}>חזרה לרשימת השאלות</div>
        <br></br>
        <div class="type-question">
        {currentQuestion?.type != 3 && (
              <>
                
                <div>בחרו את סוג השאלה{/*currentQuestion.type == 1 ? "תמונת רקע" : "בחרו את סוג השאלה"*/}</div>
                {/*<ImgUploader
                  setPicture={(mediaObject) => { updateQuestion("mediaObject", mediaObject) }}
                  hasImage={hasImage}
                  removeImage={() => { setCurrentQuestion({ ...currentQuestion, imageChanged: true, mediaObject: null, background_image: "", hasChanged : true }) }}
                  background_image={currentQuestion.background_image}
                  imageChanged={currentQuestion.imageChanged}
                  type={currentQuestion?.type}
                  uploadFromUrl={uploadFromUrl}
                  mediaObject={currentQuestion?.mediaObject}
                />*/}
              </>
            )}
            <div className="question-answers-type">
              <label htmlFor="answers-type-1">
                <input type="radio" name="answers-type" id="answers-type-1" value="1" onChange={() => { updateQuestion("answers_type", 1) }} checked={!currentQuestion?.answers_type || currentQuestion?.answers_type == 1} />
                <span className="answers-type-1">טריוויה</span>
              </label>
              או
              <label htmlFor="answers-type-2">
                <input type="radio" name="answers-type" id="answers-type-2" value="2" onChange={() => { updateQuestion("answers_type", 2) }} checked={currentQuestion?.answers_type == 2} />
                <span className="answers-type-2">סקר</span>
              </label>
              {/*<label htmlFor="answers-type-3">
                <input disabled type="radio" name="answers-type" id="answers-type-3" value="3" onChange={() => { updateQuestion("answers_type", 3) }} checked={currentQuestion?.answers_type == 3} />
                <span className="answers-type-3">מדיה <i class="fa fa-lock"></i></span>
              </label>
              */}
            </div>
            </div>
        <div className="the-question">
          שאלה
          <input
            autoFocus
            type="text"
            placeholder={currentQuestion?.answers_type === 3 ? "הכנס שם מדיה" : "נא הקלד את השאלה, עד 65 תוים"}
            maxLength="65"
            value={currentQuestion?.text}
            onChange={(e) => {
              updateQuestion("text", e.nativeEvent.target.value);
            }}
          />
          <select
            id="disable"
            name="type"
            value={currentQuestion ? currentQuestion?.type : 1}
            onChange={(e) => updateQuestion("type", e.target.value)}
          >
            <option value="1">שאלה רגילה</option>
            <option value="2" disabled>שאלת מדיה - בקרוב</option>
            <option value="3" disabled>מדיה בתשובות - בקרוב</option>
          </select>
        </div>
        <div className="question-data">
          <div class="ans-data">
            <div className="error-msg">{ansError}</div>
            {currentQuestion?.answers_type != 3 && (<QuestionAnswers />)}
            <QuestionTimer />
          </div>
          <div className="tags-area">
            <Autocomplete />
          </div>
        </div>
        <div className="question-image"></div>
          <div className="form-button">
            <button className="save-settings" onClick={questionId === "add" ? addQuest : editQuest}>
              שמור <i class="fa fa-floppy-o" aria-hidden="true"></i>
            </button>
          </div>
      </div>
    </div>
  );
}
