import React, { useState, useEffect } from 'react';
import { getTags } from '../../utils/APIs';
import { useStockContext } from '../../Contexts/StockContext';

export default function StockSearch() {

    const { searchFreeText, setSearchFreeText, searchCategories, setSearchCategories, searchTags, setSearchTags, setSearchClicked } = useStockContext();
    const [tags, setTags] = useState([]);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        getTags().then(res => {
            const { tags, categories } = res.data;
            setTags(tags);
            setCategories(categories);
        });
    }, [])

    return (
        <div id="stock-search">
            <div className="row row-1">
                <input type="text" placeholder="טקסט חופשי..." value={searchFreeText} onChange={((e) => { setSearchFreeText(e.target.value) })} />
                <select
                    onChange={(e) => { setSearchCategories(e.target.value) }}
                    value={searchCategories}
                >
                    <option> -- בחר קטגוריה --</option>
                    {categories && Object.keys(categories).map((item) => (
                        <option value={categories[item].id}>{categories[item].name}</option>
                    ))}
                </select>
            </div>
            <div className="row row-2">
                <select
                    onChange={(e) => { setSearchTags([...searchTags, e.target.value]) }}
                    value={searchTags}
                >
                    <option> -- בחר תגית --</option>
                    {tags && Object.keys(tags).map((item) => (
                        <option value={tags[item].id}>{tags[item].name}</option>
                    ))}
                </select>
                {/* <div className="advance-link">חיפוש מתקדם</div> */}
                <div><button onClick={() => { ((searchFreeText || searchCategories || Object.keys(searchTags).length) && setSearchClicked(true)) }}>חפש</button></div>
            </div>
        </div>
    );
}