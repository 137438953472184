import React from 'react';
import { CircularProgress } from "@material-ui/core";
import '../sass/_global.scss';
import "../sass/Question.scss";

export default function Loading() {

    return (
        <div className="progress-container" >
            <CircularProgress color="primary" />
        </div>)
}