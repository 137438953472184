import React, { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import QuizMenu from "../Components/QuizMenu";
import "../sass/Quiz.scss";
import "../sass/QuizImport.scss";
import { useQuizContext } from "../Contexts/QuizesContext";
import Loading from "../Components/Loading";
import { ImportDialog } from "../Components/Dialog";
import { importQuizfile } from "../utils/APIs";
import { QUIZ_IMPORT_PATH } from "./RoutesPath";
import XLSX from 'xlsx';
import Stepper from "../Components/Stepper";
import QuizGamers from "./QuizGamers";

export default function QuizImport() {
	
  const { id } = 12;//useRouteMatch(QUIZ_IMPORT_PATH).params;
  const {
    loading
  } = useQuizContext();
  
  return (
      <SheetJSApp />  
  );
}

/*
  Simple HTML Table
  usage: <OutTable data={data} cols={cols} />
    data:Array<Array<any> >;
    cols:Array<{name:string, key:number|string}>;
*/
class OutTable extends React.Component {
	constructor(props) { super(props); };
	render() { return (
<div className="table-responsive">
	<table className="table table-striped">
		<thead>
			<tr>{this.props.cols.map((c) => <th key={c.key}>{c.name}</th>)}</tr>
		</thead>
		<tbody>
			{this.props.data.map((r,i) => <tr key={i}>
				{this.props.cols.map(c => <td key={c.key}>{ r[c.key] }</td>)}
			</tr>)}
		</tbody>
	</table>
</div>
	); };
};

/*
  Simple HTML5 file drag-and-drop wrapper
  usage: <DragDropFile handleFile={handleFile}>...</DragDropFile>
    handleFile(file:File):void;
*/
class DragDropFile extends React.Component {
	constructor(props) {
		super(props);
		this.onDrop = this.onDrop.bind(this);
	};
	suppress(evt) { evt.stopPropagation(); evt.preventDefault(); };
	onDrop(evt) { evt.stopPropagation(); evt.preventDefault();
		const files = evt.dataTransfer.files;
		if(files && files[0]) this.props.handleFile(files[0]);
	};
	render() { return (
<div onDrop={this.onDrop} onDragEnter={this.suppress} onDragOver={this.suppress}>
	{this.props.children}
</div>
	); };
};

/*
  Simple HTML5 file input wrapper
  usage: <DataInput handleFile={callback} />
    handleFile(file:File):void;
*/
class DataInput extends React.Component {
	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
	};
	handleChange(e) {
		const files = e.target.files;
		if(files && files[0]) this.props.handleFile(files[0]);
	};
	render() {
		return (
			<form className="form-inline">
				<label>
					<div className="form-group add-game">
						<span htmlFor="file">טעינת רשימה מקובץ</span>
						<input type="file" className="form-control hide" id="file" accept={SheetJSFT} onChange={this.handleChange} />
					</div>
				</label>
			</form>
		);
	};
}

/* list of supported file types */
const SheetJSFT = [
	"xlsx", "xlsb", "xlsm", "xls", "xml", "csv", "txt", "ods", "fods", "uos", "sylk", "dif", "dbf", "prn", "qpw", "123", "wb*", "wq*", "html", "htm"
].map(function(x) { return "." + x; }).join(",");

/* xlsx.js (C) 2013-present  SheetJS -- http://sheetjs.com */
/* Notes:
   - usage: `ReactDOM.render( <SheetJSApp />, document.getElementById('app') );`
   - xlsx.full.min.js is loaded in the head of the HTML page
   - this script should be referenced with type="text/babel"
   - babel.js in-browser transpiler should be loaded before this script
*/
class SheetJSApp extends React.Component {
	handleFile(file/*:File*/) {
		/* Boilerplate to set up FileReader */
		const reader = new FileReader();
		const rABS = !!reader.readAsBinaryString;
		reader.onload = (e) => {
			/* Parse data */
			const bstr = e.target.result;
			const wb = XLSX.read(bstr, {type:rABS ? 'binary' : 'array'});
			/* Get first worksheet */
			const wsname = wb.SheetNames[0];
			const ws = wb.Sheets[wsname];
			/* Convert array of arrays */
			const data = XLSX.utils.sheet_to_json(ws, {header:1});
			
			console.log(ws);
			console.log(ws.A1.w);
			
			var alpha = new Array("A","B","C");
			var row = 0;
			var toJson = [];
			var last = ws["!ref"].substring(ws["!ref"].indexOf(":")+1);
			for (let gamerRow = 2; gamerRow <= 50; gamerRow++) {	
				if(alpha[0]+gamerRow == last) {
					break;
				} else {
					if(typeof ws[alpha[0]+gamerRow] !== 'undefined') {
						var gmr = {};
						gmr["name"] = ws[alpha[0]+gamerRow].v;
						gmr["phone"] = ws[alpha[1]+gamerRow].t === "n" ? "0"+ws[alpha[1]+gamerRow].w : ws[alpha[1]+gamerRow].v;
						gmr["code"] = ws[alpha[2]+gamerRow].v;
						toJson.push(gmr);
						
						//this.setHasChanges(true);
						//this.setGamers({ ...this.gamers, [row]: { name: gmr["name"], phone: gmr["phone"], group_id: 0 } });
					} else {
						break;
					}
				}
				row++;
			}
			this.props.parm(toJson);
			console.log(toJson);	
		};

		if(rABS) reader.readAsBinaryString(file); else reader.readAsArrayBuffer(file);
	};

	render() {
		return (
			<DragDropFile handleFile={this.handleFile}>
				<div className="row">
					<div>
						<DataInput handleFile={this.handleFile} />
					</div>
				</div>
			</DragDropFile>
		);
	};
};

//<OutTable data={this.state.data} cols={this.state.cols} />