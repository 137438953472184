import React from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../sass/Quiz.scss";
import { useQuizContext } from "../Contexts/QuizesContext";
import { QUIZ_SETTINGS_PATH, QUIZ_GAMERS_PATH } from "../Routes/RoutesPath";
import { Button } from "@material-ui/core";
import { editQuiz } from "../utils/APIs";

export default function SaveQuiz() {
  const { id } = useRouteMatch(QUIZ_SETTINGS_PATH).params;
  const {
    myQuizes,
    setMyQuizes,
    currentQuiz,
    setCurrentQuiz,
    setLoading
  } = useQuizContext();

  const history = useHistory();
  const saveQuiz = () => {
    setLoading(true);
    editQuiz(id, currentQuiz)
      .then((res) => {
        setMyQuizes({ ...myQuizes, [id]: { ...currentQuiz } }); //TODO remove
        setLoading(false);
        setCurrentQuiz(null);
        if(JSON.stringify(res['data']).indexOf('success') > -1){
          toast.success("נשמר בהצלחה");
          history.replace(QUIZ_GAMERS_PATH.replace(":id", id));
        } else {
          toast.error(JSON.stringify(res['data']));
          console.log(JSON.stringify(res));
        }
      })
      .catch((e) => {
        console.log(e.response);
        setMyQuizes({ ...myQuizes, [id]: { ...currentQuiz } }); //TODO remove
        setLoading(false); setCurrentQuiz(null);
        history.goBack();
      });
  };

  return (
    <div className="form-button">
      <Button className="save-settings" onClick={saveQuiz}> שמור <i class="fa fa-floppy-o" aria-hidden="true"></i></Button>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        rtl={true}
        type="success"
      />
    </div>
  );
}
