import React, { useEffect } from 'react';
import { useHistory, useRouteMatch } from "react-router-dom";
import StockQuestions from './StockQuestion';
import { STOCK_PATH, QUIZ_QUESTIONS_PATH } from "../../Routes/RoutesPath";

import '../../sass/Stock.scss';
import StockSearch from './StockSearch';
import { useStockContext } from '../../Contexts/StockContext';
import StockSearchLock from './StockSearchLock';
import { addQuestionsFromStock, getSettings } from '../../utils/APIs';
import { useQuizContext } from '../../Contexts/QuizesContext';

export default function Stock() {
    const { currentQuiz, loading, setLoading, setCurrentQuiz, setMyQuizes, myQuizes } = useQuizContext();
    const { searchClicked, questionsChoose, setQuestionsChoose } = useStockContext();
    const { id } = useRouteMatch(STOCK_PATH).params;
    const history = useHistory();

    useEffect(() => {
        if (!currentQuiz) {
            setLoading(true);
            getSettings(id)
                .then((res) => {
                    const { quiz_settings } = res.data.data;
                    setCurrentQuiz(quiz_settings);
                    setMyQuizes({ ...myQuizes, [id]: quiz_settings });
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    console.log("Error!", error);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentQuiz]);

    const addQuestions = () => {
        addQuestionsFromStock(currentQuiz?.id, { stock_questions: questionsChoose }).then((res) => {
            history.replace(QUIZ_QUESTIONS_PATH.replace(":id", currentQuiz?.id));
        }).catch((error) => {
            console.log(error.response); 
        });
    };

return (
    <div id="stock-page">
        <div className="title">מאגר שאלות</div>
        {searchClicked ? <StockSearchLock /> : <StockSearch />}
        <div class="stock-comment">
            <i class="fa fa-commenting" aria-hidden="true"></i> בחרו שאלות ע"י לחיצה על השורה.
        </div>
        <div className="action-btns">
            <button onClick={() => { setQuestionsChoose([]); addQuestions();}}><i class="fa fa-share" aria-hidden="true"></i> יבוא הנבחרים וחזרה לחידון שלי {/*currentQuiz?.name*/}</button>
            <button onClick={() => { setQuestionsChoose([]) }}>ביטול ובחירה מחדש <i class="fa fa-refresh" aria-hidden="true"></i></button>
        </div>
        <div className="stock-content">
            <StockQuestions />
        </div>
    </div>
);
}