import React, {useRef} from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route,
} from "react-router-dom";
import {
  STOCK_PATH,
  MY_QUIZS_PATH,
  QUIZ_SETTINGS_PATH,
  QUIZ_QUESTIONS_PATH,
  QUIZ_ADD_QUESTIONS,
  QUIZ_ADD_SETTINGS_PATH,
  QUIZ_EDIT_QUESTIONS,
  QUIZ_GAMERS_PATH,
  QUIZ_PATH,
  QUIZ_IMPORT_PATH
} from "./RoutesPath";
import PrivateRoute from "./PrivateRoute";
import MyQuizes from "./MyQuizes";
import Quiz from "./Quiz";
import EditQuestion from "./EditQuestion";
import Stock from "./Stock";
import QuizSettings from "./QuizSettings";
import QuizQuestions from "./QuizQuestions";
import Header from "../Components/Header";
import QuizGamers from "./QuizGamers";
import QuizImport from "./QuizImport";


const AppRouter = () => {
  return (
    <Router>
      <Header />
      <Switch>
        {/* TODO - Check token and redirect to login or my quizes */}
        <Route exact path="/בניית-חידונים/" render={() => <Redirect to={MY_QUIZS_PATH} />} />
        <PrivateRoute exact path={MY_QUIZS_PATH} component={MyQuizes} />
        <PrivateRoute exact path={STOCK_PATH} component={Stock} />
        <PrivateRoute
          exact
          path={QUIZ_ADD_QUESTIONS}
          component={EditQuestion}
        />
        {/* TODO - separate add and edit quiz */}
        <PrivateRoute exact path={QUIZ_EDIT_QUESTIONS} component={EditQuestion} />
        <PrivateRoute
          exact
          path={QUIZ_PATH}
          component={Quiz}
        />
         <PrivateRoute
          exact
          path={QUIZ_IMPORT_PATH}
          component={QuizImport}
        />
        <PrivateRoute
          exact
          path={QUIZ_SETTINGS_PATH}
          component={QuizSettings}
        />
        <PrivateRoute
          exact
          path={QUIZ_GAMERS_PATH}
          component={QuizGamers}
        />
        <PrivateRoute
          exact
          path={QUIZ_ADD_SETTINGS_PATH}
          component={QuizSettings}
        />
        <PrivateRoute
          exact
          path={QUIZ_QUESTIONS_PATH}
          component={QuizQuestions}
        />
        {/* menuActive Download */}
      </Switch>
    </Router>
  );
};

export default AppRouter;
