import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import QuestionTeaser from "./QuestionTeaser";
import { useQuestionContext } from "../Contexts/QuestionsContext";
import { styled } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';


const DndList = () => {

    const { questions, setQuestions, setOrderChanged } = useQuestionContext();

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        setQuestions(result);
        return result;
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const items = reorder(
            questions,
            result.source.index,
            result.destination.index
        );

        setQuestions(items);
        setOrderChanged(true);
    }

    const MyButton = styled(Button)({
        background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
        border: 0,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'white',
        height: 48,
        padding: '0 30px',
      });

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        {questions.map((item, index) => (
                            <Draggable style={{ display: "flex" }} key={item.id} draggableId={item.id} index={index}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        <QuestionTeaser question={item} action={true} index={index+1}/>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}

export default DndList;