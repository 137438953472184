import React, { useState, useEffect } from "react";
import logo from "../logo.png";
import { useUserContext } from "../Contexts/UserContext";
import { useHistory } from "react-router-dom";
import { getUserDetails } from "../utils/APIs";
import { MY_QUIZS_PATH } from "../Routes/RoutesPath";


export default function Header() {
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const history = useHistory();
  const { name, login, logout } = useUserContext();

  useEffect(() => {
     setInterval(() => {
      getUserDetails().then((res) => {
        const userInfo = res.data.user;
        console.log(res);
        if (userInfo) {
        //  login(userInfo);
        }
      });
    }, 20000000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="header">
      <div class="full-width">
        <button id="main-btn" class="action-btns" onClick={() => {
            history.replace(MY_QUIZS_PATH);}}>
              החידונים שלי <i class="fa fa-list-ol" aria-hidden="true"></i>
            </button>
      </div>
      {/*}<div className="user-menu">
        <ul>
          <li onClick={() => {
            history.replace(MY_QUIZS_PATH);
          }}>החידונים שלי</li>
          <li>
            <a href="https://clickef.com/shop/" rel="noopener noreferrer">
              חנות החידונים
                </a>
          </li>
        </ul>
      </div>
      <div className="user-menu">
        <div
          className="user-menu-title"
          onClick={() => {
            setUserMenuOpen(!userMenuOpen);
          }}
        >
          <span>
            שלום{" "}
            <b>
              {name}
            </b>
          </span>
          <span className="triangular"></span>
        </div>
        <div
          className="user-menu-items"
          style={{ display: userMenuOpen ? "inherit" : "none" }}
        >
          <ul>
            <li onClick={() => {
              history.replace(MY_QUIZS_PATH);
              setUserMenuOpen(false);
            }}>החידונים שלי</li>
            <li onClick={() => {
              history.replace(MY_QUIZS_PATH);
              setUserMenuOpen(false);
            }}>
              <a href="https://clickef.com/%d7%94%d7%97%d7%99%d7%93%d7%95%d7%9f-%d7%a9%d7%9c%d7%9b%d7%9d/" target="_blank" rel="noopener noreferrer">
                חנות החידונים
                </a>
            </li>
            <li onClick={logout}>יציאה</li>
          </ul>
        </div>
          </div> */}
    </div >
  );
}
