import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Input } from '@material-ui/core';

export default function AlertDialog({ dialogOpen, onOk, title, okText, onCancel, showInput }) {
    const [open, setOpen] = React.useState(dialogOpen);
    const [urlAddress, setUrlAddress] = React.useState();

    const updateUrl = e => {
        const { value } = e.nativeEvent.target;
        setUrlAddress(value)
    }
    useEffect(() => {
        setOpen(dialogOpen);
    }, [dialogOpen])

    const handleClose = () => {
        onCancel && onCancel();
        setOpen(false);
    };

    const handleOkAndClose = () => {
        if (showInput && !urlAddress) return;
        onOk(urlAddress);
        handleClose();
    }
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                {showInput && <DialogActions>
                    <Input value={urlAddress} onChange={updateUrl} />
                </DialogActions>
                }
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        ביטול
                    </Button>
                    {typeof okText !== "undefined" ? (
                    <Button onClick={handleOkAndClose} color="primary" autoFocus>
                        {okText}
                    </Button>
                    ):false}
                </DialogActions>
            </Dialog>
        </div>
    );
}

export function ImportDialog({ dialogOpen, onOk, onCancel }) {
    const [open, setOpen] = React.useState(dialogOpen);

    useEffect(() => {
        setOpen(dialogOpen);
    }, [dialogOpen])

    const handleClose = () => {
        onCancel && onCancel();
        setOpen(false);
    };

    const handleOkAndClose = () => {
        onOk();
        handleClose();
    }
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">שימו לב! העלאת אקסל מוחקת את כל נתוני החידון אם בניתם כבר במערכת.</DialogTitle>
                <div dir="rtl">האם להמשיך?</div>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        ביטול
          </Button>
                    <Button onClick={handleOkAndClose} color="primary" autoFocus>
                        המשך
          </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export function ExportDialog({ dialogOpen }) {
    const [open, setOpen] = React.useState(dialogOpen);

    useEffect(() => {
        setOpen(dialogOpen);
    }, [dialogOpen])

    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">תהליך הייצוא מתבצע, נא להמתין...</DialogTitle>
            </Dialog>
        </div>
    );
}
