import React, { useState } from "react";

export const createQuestionContextValue = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [questions, setQuestions] = useState([]);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [currentQuestion, setCurrentQuestion] = useState({});
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [orderChanged, setOrderChanged] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [tags, setTags] = useState([]);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [removedTags, setRemovedTags] = useState([]);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [addedTags, setAddedTags] = useState([]);


  return {
    questions,
    setQuestions,
    currentQuestion,
    setCurrentQuestion,
    orderChanged,
    setOrderChanged,
    tags,
    setTags,
    removedTags,
    setRemovedTags,
    addedTags,
    setAddedTags
  };
};

export const QuestionContext = React.createContext(null);

export const QuestionContextProvider = (props) => {
  const value = createQuestionContextValue();

  return (
    <QuestionContext.Provider value={value}>
      {props.children}
    </QuestionContext.Provider>
  );
};

export const useQuestionContext = () => React.useContext(QuestionContext);
