
import React, { useState } from "react";

export const quizNames = ["חידון בסיסי באזז קליק", "פון קליק", "קליק פור יו", "קליקכיף אירועים"]
export const categoryNames = ["קטגוריה 1", "קטגוריה 2", "קטגוריה 3", "קטגוריה 4"]

export const createQuizContextValue = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [myQuizes, setMyQuizes] = useState({});
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [currentQuiz, setCurrentQuiz] = useState(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loading, setLoading] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [categoriesList, setCategoriesList] = useState(null);
    
    return {
        myQuizes,
        setMyQuizes,
        currentQuiz,
        setCurrentQuiz,
        loading,
        setLoading,
        categoriesList,
        setCategoriesList
    };
};

export const QuizContext = React.createContext(null);

export const QuizContextProvider = (props) => {
    const value = createQuizContextValue();

    return (
        <QuizContext.Provider value={value}>
            {props.children}
        </QuizContext.Provider>
    );
};

export const useQuizContext = () => React.useContext(QuizContext);
