import React, { useEffect } from "react";

import "../sass/Question.scss";
import QuizMenu from "../Components/QuizMenu";
import Stepper from "../Components/Stepper";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  QUIZ_ADD_QUESTIONS,
  STOCK_PATH,
  QUIZ_QUESTIONS_PATH,
} from "../Routes/RoutesPath";
import { useQuestionContext } from "../Contexts/QuestionsContext";
import { getQuestions } from "../utils/APIs";
import { useQuizContext } from "../Contexts/QuizesContext";
import Loading from "../Components/Loading";
import DndList from "../Components/DndList";
import SaveOrder from "../Components/SaveOrder";

export default function QuizQuestions() {
  const { setQuestions, setOrderChanged } = useQuestionContext();
  const { loading, setLoading } = useQuizContext();
  const { setCurrentQuestion } = useQuestionContext();
  const { id } = useRouteMatch(QUIZ_QUESTIONS_PATH).params;

  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    getQuestions(id).then((res) => {
      const tmp = {};
      const { question_list } = res.data.data;
      question_list.forEach((element) => {
        tmp[element.id] = element;
      });
      setQuestions(question_list);
      setOrderChanged(false);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="quiz-question">
      <Stepper />
      {loading && <Loading />}
      <div className="quiz-content">
        <div className="top-btns">
          <button
            className="add-question"
            onClick={() => {
              setCurrentQuestion({tags: []});
              history.push(QUIZ_ADD_QUESTIONS.replace(":id", id));
            }}
          >
            הוסף שאלה <i class="fa fa-plus-circle" aria-hidden="true"></i>
        </button>
          <button
            className="import-questions"
            onClick={() => {
              history.push(STOCK_PATH.replace(":id", id));
            }}
          >
            יבוא שאלות מהמאגר <i class="fa fa-exchange" aria-hidden="true"></i>
        </button>
        </div>
        <div className="orderComment">
          
        </div>
        <div className="questions-list">
          <DndList />
        </div>
      </div>
      <SaveOrder />
    </div>
  );
}
